import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';
import PasswordResetForm from '../components/PasswordResetForm';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import Footer from "../components/Footer";

const LoginPage = () => {
    const [currentForm, setCurrentForm] = useState('login');
    const history = useHistory();

    const switchForm = (formName) => {
        setCurrentForm(formName);
    };

    const handleBackToLogin = () => {
        switchForm('login');
    };

    const renderCurrentForm = () => {
        switch (currentForm) {
            case 'login':
                return (
                    <LoginForm
                        onSwitch={() => setCurrentForm('register')}
                        forgotPassword={() => setCurrentForm('forgotPassword')}
                    />
                );
            case 'forgotPassword':
                return <ForgotPasswordForm onBack={() => setCurrentForm('login')} />;
            case 'register':
                return <RegisterForm onBack={() => switchForm('login')} />;
            case 'reset':
                return <PasswordResetForm onSwitch={() => switchForm('login')} />;
            case 'TobackLogin':
                return <RegisterForm onSwitch={handleBackToLogin} />;
            default:
                return null;
        }
    };

    return (
        <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{
            width: '100vw',
            minHeight: '100vh',
            background: `url('https://cajuu.app/newapp_front/img_login5.png') center 87% / cover no-repeat`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <section className="flex-grow">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full" style={{ maxWidth: '500px', minHeight: '400px' }}>
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            {renderCurrentForm()}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default LoginPage;