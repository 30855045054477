import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import Swal from 'sweetalert2';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const LoginForm = ({ onSwitch, forgotPassword, formType = 'login' }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/login`, { email, password });
            localStorage.setItem('userToken', response.data.data.token);
            const decodedToken = jwtDecode(response.data.data.token);
            setIsLoading(false);

            const quoteId = localStorage.getItem('quoteId');
            if (quoteId !== null) {
                localStorage.removeItem('quoteId');
                history.push(`/Dashboard`);
            } else {
                const userHomePage = decodedToken.home_page || '/';
                history.push(userHomePage);
            }
        } catch (err) {
            setError(err.response ? err.response.data.message : 'An error occurred in communication with the server.');
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response ? err.response.data.message : 'An error occurred in communication with the server.'
            });
        }
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const isLoginForm = formType === 'login';

    return (
        <section className="flex flex-col items-center justify-center mt-16">
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <a href="#" className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img className="w-12 h-12" src="./cajuu.png" alt="logo" />
                    </a>
                    <h1 className="flex items-center justify-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        {isLoginForm ? 'Sign in to your account' : 'Register'}
                    </h1>
                    <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="name@company.com"
                                required
                            />
                        </div>
                        <div className="relative">
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="••••••••"
                                required
                            />
                            <button
                                type="button"
                                onClick={handleClickShowPassword}
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 focus:outline-none focus:ring-0"
                                style={{ border: 'none', background: 'transparent', padding: '5px' }}
                            >
                                {showPassword ? (
                                    <FaEyeSlash className="h-5 w-5 text-gray-500 mt-6" />
                                ) : (
                                    <FaEye className="h-5 w-5 text-gray-500 mt-6"/>
                                )}
                            </button>
                        </div>
                        <div className="flex items-center justify-end">
                            <button
                                type="button"
                                onClick={forgotPassword}
                                className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                            >
                                Forgot password?
                            </button>
                        </div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
                        >
                            {isLoading ? (isLoginForm ? 'Logging in...' : 'Registering...') : (isLoginForm ? 'Login' : 'Register')}
                        </button>

                        <button
                            type="button"
                            onClick={() => history.push('/SelectOptionsLogin')}
                            className="mt-4 text-sm font-light text-gray-500 hover:text-primary-600 dark:text-gray-400 cursor-pointer"
                        >
                            {isLoginForm ? 'Don’t have an account yet? Sign up' : 'Already have an account? Sign in'}
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default LoginForm;
