import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const QuoteForm = () => {
  const location = useLocation();
  const [quote, setQuote] = useState(null);
  const [quoteDetails, setQuoteDetails] = useState({
    name: '',
    phone: '',
    pickupLocation: '',
    deliveryLocation: '',
    transportType: '',
    email: '',
    pickupDate: '',
    paymentRate: '',
  });

  const [budget, setBudget] = useState('');

  const handleBudgetChange = (e) => {
    setBudget(e.target.value);
  };

  const handleSend = async (e) => {

    const payload = {
      maximum_budget: parseFloat(budget),
    };

    try {
      const userToken = localStorage.getItem('userToken');

      const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/quote/maximum-budget/${quote.id}`,
        payload,
        {
          headers: {
            "Screen-Name": "/Quotes",
            "Authorization": `Bearer ${userToken}`
          }
        });
      console.log("Formulário enviado com sucesso:", response.data);
      // Mostra um alerta de sucesso
      Swal.fire({
        title: 'Success!',
        text: 'Form sent successfully!',
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then(() => {
        //setFormData(initialFormData);
      });
    } catch (error) {
      console.error("There was an error submitting the form:", error);

      let errorMessage = 'Request failed';
      if (error.response && error.response.data) {
        errorMessage = `${error.response.data.message} - ${error.response.data.error}`;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    } finally {
      //setLoading(false); // End loading
    }
  };

  const mapQuoteToQuoteDetails = (quote) => {
    return {
      name: quote.name,
      phone: quote.phone,
      pickupLocation: quote.pickup_city + ", " + quote.pickup_state + (quote.pickup_zipcode ? " - " + quote.pickup_zipcode : ''),
      deliveryLocation: quote.delivery_city + ", " + quote.delivery_state + (quote.delivery_zipcode ? " - " + quote.delivery_zipcode : ''),
      transportType: quote.transport_type,
      email: quote.email,
      pickupDate: quote.pickup_date,
      paymentRate: quote.payment_rate,
    };
  };

  useEffect(() => {
    const fetchQuote = async () => {
      const userToken = localStorage.getItem('userToken');
      const idQuote = new URLSearchParams(location.search).get('id');

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_PREFIX}/quote/${idQuote}`, {
          headers: {
            "Screen-Name": "/Quotes",
            "Authorization": `Bearer ${userToken}`
          }
        });
        const quoteData = response.data.data; // Ajuste conforme a estrutura da sua resposta
        setQuote(quoteData); // Ajuste conforme a estrutura da sua resposta
        setQuoteDetails(mapQuoteToQuoteDetails(quoteData));
      } catch (error) {
        console.error("Erro ao buscar dados da quote:", error);
      }
    };

    fetchQuote();

  }, [location.search]);

  return (
    <div className="flex flex-col items-center bg-gray-100 py-8">
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
        <div className="text-center mb-4">
          <h2 className="text-2xl font-bold mb-2"></h2>
          <a href="/" className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img className="w-12 h-12" src="./cajuu.png" alt="logo" />
          </a>
          <div className="space-y-1">
            {quote && quote.quote_vehicles.map((vehicle, index) => (
              <p key={index} className="text-lg font-semibold">
                {vehicle.year} {vehicle.make} {vehicle.model}
                <span className={vehicle.operable ? "text-green-600" : "text-red-600"}>
                  {vehicle.operable ? "(OP)" : "(INOP)"}
                </span>
              </p>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Name:</label>
            <input
              type="text"
              value={quoteDetails.name}
              readOnly
              className="w-full px-4 py-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Phone:</label>
            <input
              type="text"
              value={quoteDetails.phone}
              readOnly
              className="w-full px-4 py-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Pickup Location:</label>
            <input
              type="text"
              value={quoteDetails.pickupLocation}
              readOnly
              className="w-full px-4 py-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Delivery Location:</label>
            <input
              type="text"
              value={quoteDetails.deliveryLocation}
              readOnly
              className="w-full px-4 py-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Transport Type:</label>
            <input
              type="text"
              value={quoteDetails.transportType}
              readOnly
              className="w-full px-4 py-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Email:</label>
            <input
              type="email"
              value={quoteDetails.email}
              readOnly
              className="w-full px-4 py-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Pickup Date:</label>
            <input
              type="text"
              value={quoteDetails.pickupDate}
              readOnly
              className="w-full px-4 py-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Payment Rate:</label>
            <input
              type="text"
              value={quoteDetails.paymentRate}
              readOnly
              className="w-full px-4 py-2 border rounded bg-gray-100"
            />
          </div>
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 mt-6">
        <div className="text-center mb-4">
          <h2 className="text-xl font-bold">Please, enter below your budget:</h2>
        </div>
        <div className="flex flex-col items-center">
          <input
            type="text"
            value={budget}
            onChange={handleBudgetChange}
            placeholder="Enter budget"
            className="w-full md:w-1/2 px-4 py-2 border rounded mb-4"
          />
          <button
            onClick={handleSend}
            className="bg-orange-500 text-white font-bold py-2 px-8 rounded hover:bg-orange-600"
          >
            Send
          </button>
        </div>
      </div>

      <footer className="py-4 sm:py-6 px-4 sm:px-16 border-t border-gray-200 text-xs sm:text-sm md:text-base font-light flex flex-col items-center lg:flex-row justify-center space-y-4 lg:space-y-0 lg:space-x-4">
        <p className="text-white mb-6 lg:mb-0 text-center lg:text-left" style={{ textShadow: '0px 0px 10px black' }}>
          Copyright &copy; {new Date().getFullYear()} - Cajuu™ by{' '}
          {/* <a
            href="https://j316transport.com"
            target="_blank"
            rel="noreferrer"
            className="text-red-500 hover:text-red-700"
          >
          </a> */}
        </p>
      </footer>
    </div>
  );
};

export default QuoteForm;
