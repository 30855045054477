// src/hooks/usePreviousRoute.js
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function usePreviousRoute() {
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState('/');

  useEffect(() => {
    return () => {
      // Atualiza o caminho anterior apenas quando o componente vai desmontar
      setPreviousPath(location.pathname);
    };
  // Dependendo da sua implementação, você pode precisar remover location das dependências para evitar atualizações desnecessárias
  }, [location.pathname]);

  return previousPath;
}

export default usePreviousRoute;
