import React, { useState, useEffect } from 'react';
import { TbTrashX } from 'react-icons/tb';
import axios from 'axios';
import Swal from 'sweetalert2';

const AddPhotosModal = ({ isOpen, onClose, idTransport, loadItems }) => {
    const [images, setImages] = useState({ pickup: [], dropoff: [] });
    const [selectedLoadItemId, setSelectedLoadItemId] = useState('');

    useEffect(() => {
        if (loadItems.length === 1) {
            setSelectedLoadItemId(loadItems[0].id);
        }
    }, [loadItems]);

    const handleFileChange = (cardId) => (event) => {
        const filesWithPreview = Array.from(event.target.files)
            .filter(file => file instanceof File)
            .map(file => ({
                file,
                name: file.name,
                preview: URL.createObjectURL(file)
            }));

        setImages(prevImages => ({
            ...prevImages,
            [cardId]: [...prevImages[cardId], ...filesWithPreview]
        }));
    };

    const handleSubmit = async () => {
        const userToken = localStorage.getItem('userToken');
        if (!userToken) {
            Swal.fire({
                title: 'Erro!',
                text: 'Você não está autenticado. Por favor, faça login novamente.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }

        let errored = false;
        const formData = new FormData();
        formData.append('document', 'algum_valor');
        formData.append('origin', 'algum_valor');
        formData.append('inspection_type', 'Pickup');
        formData.append('inspection_type', 'Dropoff');

        images['pickup'].forEach((fileObj) => {
            formData.append('photos[]', fileObj.file);
        });
        images['dropoff'].forEach((fileObj) => {
            formData.append('photos[]', fileObj.file);
        });

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_PREFIX}/inspectionloaditem/photos/${selectedLoadItemId}`,
                formData,
                {
                    headers: {
                        "Authorization": `Bearer ${userToken}`,
                        "Content-Type": "multipart/form-data",
                        "Screen-Name": "/Load"
                    }
                }
            );
            console.log('All photos uploaded successfully:', response.data);
            Swal.fire({
                title: 'Success!',
                text: 'All photos uploaded successfully!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                onClose();
            });
        } catch (error) {
            console.error('Error uploading photos:', error);
            Swal.fire({
                title: 'Error!',
                text: `Failed to upload photos.`,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            errored = true;
        }

        if (!errored) {
            setImages({ pickup: [], dropoff: [] });
        }
    };

    const excluirImagem = (cardId, index) => {
        setImages(currentImages => ({
            ...currentImages,
            [cardId]: currentImages[cardId].filter((_, i) => i !== index)
        }));
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex justify-center items-center z-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-content bg-white rounded-lg shadow-xl w-11/12 md:w-3/4 overflow-y-auto" onClick={e => e.stopPropagation()} style={{ maxHeight: '90vh' }}>
                <div className="modal-header flex justify-between items-center px-4 py-2 bg-gray-100">
                    <h5 className="modal-title text-xl font-semibold text-gray-700">Add Inspection Photos</h5>
                    <select
                        value={selectedLoadItemId}
                        onChange={(e) => setSelectedLoadItemId(e.target.value)}
                        className="w-80 p-2 border rounded"
                    >
                        {loadItems.length > 1 ? (
                            <option value="">Select vehicle</option>
                        ) : null}
                        {loadItems.map((loadItem) => (
                            <option key={loadItem.id} value={loadItem.id}>
                                {loadItem.make} {loadItem.model} - {loadItem.year}
                            </option>
                        ))}
                    </select>

                    <button className="close text-gray-500 hover:text-gray-700" onClick={onClose}>
                        &times;
                    </button>
                </div>

                <div className="modal-body p-4">
                    {['pickup', 'dropoff'].map((cardId) => (
                        <div key={cardId}>
                            <div className="flex flex-wrap w-full p-4 bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg mt-8">
                                <label
                                    htmlFor={`dropzone-file-${cardId}`}
                                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500"
                                    style={{ marginTop: "2rem" }}
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="icone w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 00 0-6h-.025A5.56 5.56 0 0016 6.5A5.5 5.5 0 005.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 000 8h2.167M10 15V6m0 0L8 8m2-2l2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-semibold">Click to upload</span> or pickup
                                            and dropoff
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                            SVG, PNG, JPG or GIF (MAX. 800x400px)
                                        </p>
                                    </div>
                                    <input
                                        id={`dropzone-file-${cardId}`}
                                        type="file"
                                        multiple
                                        className="hidden"
                                        onChange={handleFileChange(cardId)}
                                    />
                                </label>
                            </div>
                            <div className="flex flex-wrap justify-start items-center">
                                {images[cardId].map((image, index) => (
                                    <div key={index} className="relative m-2">
                                        <img
                                            src={image.preview}
                                            alt={image.name}
                                            className="w-32 h-32 object-cover rounded-lg"
                                            style={{ width: "128px", height: "128px" }}
                                        />
                                        <button
                                            onClick={() => excluirImagem(cardId, index)}
                                            className="absolute top-0 right-0 bg-red-600 text-white p-1 rounded-full"
                                        >
                                            <TbTrashX />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="modal-footer flex justify-between items-center px-4 py-3 bg-gray-100">
                    <button type="button" className="py-2 px-4 text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200" onClick={onClose}>Cancel</button>
                    <button type="button" className="btn py-2 px-4 text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200" onClick={handleSubmit}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default AddPhotosModal;
