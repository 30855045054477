import React from 'react';
import Footer from "../components/Footer";
import RegisterForm from '../components/RegisterForm';

const RegisterUser = () => {
    return (
        <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{
            width: '100vw',
            minHeight: '100vh',
            background: `url('https://cajuu.app/newapp_front/img_login5.png') center 87% / cover no-repeat`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <section className="flex-grow">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full" style={{ maxWidth: '500px', minHeight: '400px' }}>
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <RegisterForm />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default RegisterUser;