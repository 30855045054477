import React from 'react';
import { TbFiretruck } from "react-icons/tb";
import { FaHandshake } from "react-icons/fa6";
import { FaUserPlus } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import '../components/css/SelectOptionsLogin.css';

const SelectOptionsLogin = () => {
    const history = useHistory();

    const handleGetStartedCarrier = () => {
        history.push('/carrier-register');
    };

    const handleGetStartedShipper = () => {
        history.push('/DriverRegister');
    };

    const handleGetStartedUser = () => {
        history.push('/RegisterUser');
    };

    return (
        <>
            {/* Logo no canto superior esquerdo */}
            <a href="/" className="fixed top-0 left-0 p-6 flex items-center">
                <img className="w-12 h-12 mr-2" src="./cajuu.png" alt="logo" />
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white" style={{ fontFamily: 'sans-serif' }}>
                    Cajuu
                </h1>
            </a>
            <div className="flex justify-around items-center px-2 container-mobile" style={{ height: '70vh', marginTop: '-3rem' }}>
                {/* Card for Carrier Sign Up */}
                <div className="bg-white rounded-lg shadow-xl overflow-hidden max-w-md card-mobile" style={{ width: '30%' }}>
                    <div className="p-6">
                        <div className="flex items-center justify-center ">
                            <div className="flex items-center justify-center h-16 w-16 rounded-md bg-orange-500 text-black">
                                <TbFiretruck className="text-4xl" />
                            </div>
                        </div>
                        <h3 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Carrier Sign Up</h3>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                            A car hauler dispatch software that streamlines everything into one view, effortlessly integrates your entire workflow and automates partner notifications.
                        </p>
                    </div>
                    <div className="px-6 py-4 bg-gray-50 text-right">
                        <button
                            onClick={handleGetStartedCarrier}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150"
                            type='button'
                        >
                            Get Started
                        </button>
                    </div>
                </div>

                {/* Card for Shipper Sign Up */}
                <div className="bg-white rounded-lg shadow-xl overflow-hidden max-w-md card-mobile" style={{ width: '30%' }}>
                    <div className="p-6">
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center h-16 w-16 rounded-md bg-orange-500 text-black">
                                <FaHandshake className="text-4xl" />
                            </div>
                        </div>
                        <h3 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Driver Sign Up</h3>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                            Take your business to the next level, consolidate your workflow into one platform with a powerful and nimble transport management solution.
                        </p>
                    </div>
                    <div className="px-6 py-4 bg-gray-50 text-right">
                        <button
                            onClick={handleGetStartedShipper}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150"
                            type='button'
                        >
                            Get Started
                        </button>
                    </div>
                </div>
            </div>

            {/* Novo card para registro de usuário sem vínculo */}
            <div className="flex justify-center container-mobile-2" style={{ marginTop: '-6rem' }}>
                <div className="bg-white rounded-lg shadow-xl overflow-hidden max-w-md card-mobile" style={{ width: '30%' }}>
                    <div className="p-6">
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center h-16 w-16 rounded-md bg-orange-500 text-black">
                                <FaUserPlus className="text-4xl" />
                            </div>
                        </div>
                        <h3 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">User Registration</h3>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                            Register as an independent user to gain access to our features and start managing your transport needs with ease.
                        </p>
                    </div>
                    <div className="px-6 py-4 bg-gray-50 text-right">
                        <button
                            onClick={handleGetStartedUser}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150"
                            type='button'
                        >
                            Register Now
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectOptionsLogin;