import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import Spinner from '../components/MainSpinner';

export default function DashboardDriver() {
    const [partnerCompanyName, setPartnerCompanyName] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Recupera o token do localStorage
        const token = localStorage.getItem('userToken');
        if (token) {
            // Decodifica o token para obter o nome da empresa parceira
            const decodedToken = jwtDecode(token);
            setPartnerCompanyName(decodedToken.partner_company_name || 'unknown company');
        }

        // Simula o carregamento
        setTimeout(() => {
            setLoading(false);
        }, 2000); // 3 segundos
    }, []);

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="bg-orange-500 px-3 md:px-8 h-40" />
            <div className="px-3 md:px-8 -mt-10">
                <div style={{ marginTop: '-7rem' }} className="container mx-auto max-w-full text-center">
                    <h1 className="text-white text-4xl md:text-5xl font-bold drop-shadow-lg">Welcome to Cajuu.app</h1>
                    <p className="text-white text-xl md:text-2xl mt-2 animate-pulse">Your logistics partner company, {partnerCompanyName}</p>
                </div>
            </div>

            <div className="px-3 md:px-8 mt-10" style={{ marginTop: '30rem' }}>
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-5">
                        {/* Conteúdo adicional pode ser adicionado aqui */}
                    </div>
                </div>
            </div>
        </>
    );
}
