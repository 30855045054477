import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../components/css/RegisterForm.css';

const LoginForm = ({ onSwitch, forgotPassword, formType = 'login' }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();

    const handleLoginClick = () => {
        history.push('/login');
    };

    return (
        <section className="flex flex-col items-center justify-center mt-16">
            <div className="w-full bg-white rounded-lg shadow-lg dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-6 sm:p-8">
                    <div className="flex items-center justify-center mb-6">
                        <img className="w-16 h-16" src="./cajuu.png" alt="logo" />
                    </div>
                    <h1 className="text-2xl font-bold text-center text-gray-900 md:text-3xl dark:text-white">
                        Welcome to Cajuu
                    </h1>
                    <h2 className="text-lg font-semibold text-center text-green-800 md:text-xl dark:text-green-400">
                        Your email has been successfully verified
                    </h2>
                    <button
                        onClick={handleLoginClick}
                        disabled={isLoading}
                        className="w-full py-3 mt-4 text-lg font-medium text-white bg-orange-500 rounded-lg hover:bg-orange-600 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
                    >
                        Back to Login
                    </button>
                </div>
            </div>
        </section>
    );
};

export default LoginForm;
