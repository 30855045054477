import React, { useEffect, useState } from 'react';
import '../components/css/MainSpinner.css'; // Certifique-se de criar este arquivo para estilizações adicionais
import spinnerImage from '../assets/img/cajuu4.png';

const Spinner = () => {
    return (
        <div className="spinner-overlay">
            <div className="spinner-icon animate-float">
                <img src={spinnerImage} alt="Loading" className="spinner-image" />
            </div>
        </div>
    );
};

export default Spinner;
