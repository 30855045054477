import React from 'react';
import GalleryInspection from '../components/GalleryInspection';
import Footer from '../components/Footer'

const Gallery = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-600 p-4 text-white text-center">
        <a href="#" className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-12 h-12" src="./cajuu.png" alt="logo" />
        </a>
        <h1 className="text-2xl font-bold text-black">Load Item Inspection Gallery</h1>
      </header>
      <main>
        <GalleryInspection />
      </main>
      <Footer />
    </div>
  );
};

export default Gallery;
