import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaExclamationTriangle } from 'react-icons/fa';

// Componente para exibir cada foto
const Photo = ({ src, alt }) => (
    <div className="p-2 bg-white shadow-md rounded-lg">
        <img className="object-cover w-full h-full rounded-lg" src={src} alt={alt} />
    </div>
);

// Componente da galeria
const Gallery = () => {
    const [photos, setPhotos] = useState([]);

    // Função para simular a recuperação de fotos de um servidor
    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const response = await axios.get('https://api.example.com/load-item-photos');
                setPhotos(response.data);
            } catch (error) {
                console.error("Error fetching photos:", error);
            }
        };
        fetchPhotos();
    }, []);

    return (
        <div className="p-4 flex flex-col items-center">
            {photos.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
                    {photos.map((photo, index) => (
                        <Photo key={index} src={photo.url} alt={`Inspection photo ${index + 1}`} />
                    ))}
                </div>
            ) : (
                <div className="flex flex-col items-center text-center text-gray-500 mt-8">
                    <FaExclamationTriangle className="text-4xl mb-4 text-red-500" />
                    <p className="text-lg">No photos available</p>
                </div>
            )}
        </div>
    );
};

export default Gallery;
