import React from 'react';
import Footer from "../components/Footer";
import Emailverified from '../components/emailverified'; // Certifique-se de que o nome corresponda ao nome do arquivo

const EmailVerifiedPage = () => {
    return (
        <>
            <section className="flex-grow">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full" style={{ maxWidth: '500px', minHeight: '400px' }}>
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <Emailverified/>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default EmailVerifiedPage;