import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (

        <div className={`${isOpen ? '' : 'hidden'} fixed inset-0 z-50 overflow-auto bg-opacity-40 flex justify-center items-start pt-10`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="flex justify-center items-center min-h-screen">
                <div className="bg-white p-4 rounded shadow-lg">
                    <button onClick={onClose}>Close</button>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
