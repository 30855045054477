import React from 'react';
import { FaRegFilePdf } from 'react-icons/fa';

const PdfSelectionModal = ({ isOpen, onClose, onSelect }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="bg-white rounded-lg p-8 w-1/3 shadow-lg">
                <h2 className="text-2xl font-semibold text-gray-800 mb-6 border-b pb-4">Select PDF Type</h2>
                <div className="space-y-4">
                    <button
                        onClick={() => onSelect('gatepass')}
                        className="flex items-center justify-between bg-orange-500 text-white py-3 px-6 rounded-lg shadow-md  transition-transform transform hover:scale-105 w-full"
                    >
                        <div className="flex items-center">
                            <FaRegFilePdf className="mr-3 text-2xl" />
                            <span className="text-lg font-medium">Gatepass</span>
                        </div>
                        <span className="text-sm bg-blue-800 py-1 px-3 rounded-full">PDF</span>
                    </button>
                    <button
                        onClick={() => onSelect('release')}
                        className="flex items-center justify-between bg-orange-500 text-white py-3 px-6 rounded-lg shadow-md transition-transform transform hover:scale-105 w-full"
                    >
                        <div className="flex items-center">
                            <FaRegFilePdf className="mr-3 text-2xl" />
                            <span className="text-lg font-medium">Release</span>
                        </div>
                        <span className="text-sm bg-blue-800 py-1 px-3 rounded-full">PDF</span>
                    </button>
                    <button
                        onClick={() => onSelect('others')}
                        className="flex items-center justify-between bg-orange-500 text-white py-3 px-6 rounded-lg shadow-md transition-transform transform hover:scale-105 w-full"
                    >
                        <div className="flex items-center">
                            <FaRegFilePdf className="mr-3 text-2xl" />
                            <span className="text-lg font-medium">Others</span>
                        </div>
                        <span className="text-sm bg-blue-800 py-1 px-3 rounded-full">PDF</span>
                    </button>
                </div>
                <button
                    onClick={onClose}
                    className="mt-6 flex items-center justify-center bg-gray-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-gray-700 transition-transform transform hover:scale-105 w-full"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default PdfSelectionModal;
