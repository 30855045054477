import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useMediaQuery } from 'react-responsive';
import 'pdfjs-dist/es5/build/pdf.worker.entry';
import ScrollToTopButton from '../components/ScrollToTopButton';
import TransportCard from '../components/TransportCard';
import '../components/css/Load.css';
import Spinner from '../components/MainSpinner';

function LoadTransportPage() {
    const [drivers, setDrivers] = useState([]);
    const [partnerCompanies, setPartnerCompanies] = useState([]);
    const [shippers, setShippers] = useState([]);
    const [cartypes, setCartypes] = useState([]);
    const [transports, setTransports] = useState([]);
    const [loadStatus, setLoadStatus] = useState([]);
    const [currentIdselected, setCurrentIdselected] = useState();
    const [editMode, setEditMode] = useState(false);
    const [cars, setCars] = useState([]);
    const [showLimitWarning, setShowLimitWarning] = useState(false);
    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState({
        status: '',
        driver: '',
        orderId: '',
        vehicle: '',
        id: '',
        orderBy: 'dispatch_date',
        sort: 'desc'
    });

    const [filteredTransports, setFilteredTransports] = useState([]);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const addCar = (event) => {
        event.preventDefault();
        if (cars.length < 4) {
            setCars([...cars, {}]);
            setShowLimitWarning(false);
        } else {
            setShowLimitWarning(true);
        }
    };

    const deleteCar = (index) => {
        const newCars = cars.filter((_, carIndex) => carIndex !== index);
        setCars(newCars);
    };

    const setFormFields = async (transport) => {
        document.getElementById("driver").value = transport.driver_id;
        document.getElementById("paymentMethod").value = transport.payment_method;
        document.getElementById("paymentType").value = transport.payment_type;
        document.getElementById("billed").checked = transport.billed;
        document.getElementById("paid").checked = transport.paid;
        document.getElementById("carType").value = transport.car_type_id;
        document.getElementById("isOperable").checked = transport.is_operable;
        document.getElementById("shipper").value = transport.shipper_id;
        document.getElementById("partnerCompany").value = transport.partner_company_id;
        document.getElementById("loadboard").value = transport.loadboard;
        document.getElementById("orderId").value = transport.order_id;
        document.getElementById("paymentRate").value = transport.payment_rate;
        document.getElementById("expectedPickupDate").value = transport.expected_pickup_date;
        document.getElementById("expectedDropoffDate").value = transport.expected_dropoff_date;
        document.getElementById("pickupDate").value = transport.pickup_date;
        document.getElementById("dropoffDate").value = transport.dropoff_date;
        document.getElementById("pickupCustomerPhone").value = transport.pickup_customer_phone;
        document.getElementById("pickupCustomerEmail").value = transport.pickup_customer_email;
        document.getElementById("pickupCustomerName").value = transport.pickup_customer_name;
        document.getElementById("deliveryCustomerPhone").value = transport.delivery_customer_phone;
        document.getElementById("deliveryCustomerEmail").value = transport.delivery_customer_email;
        document.getElementById("deliveryCustomerName").value = transport.delivery_customer_name;
        document.getElementById("pickupAddress").value = transport.pickup_address;
        document.getElementById("pickupCity").value = transport.pickup_city;
        document.getElementById("pickupState").value = transport.pickup_state;
        document.getElementById("pickupZip").value = transport.pickup_zipcode;
        document.getElementById("deliveryAddress").value = transport.delivery_address;
        document.getElementById("deliveryCity").value = transport.delivery_city;
        document.getElementById("deliveryState").value = transport.delivery_state;
        document.getElementById("deliveryZip").value = transport.delivery_zipcode;

        const userToken = localStorage.getItem('userToken');

        const loadItemResponse = await axios.get(
            `${process.env.REACT_APP_API_PREFIX}/loaditem/${transport.id}`, {
            headers: {
                "Screen-Name": "/NewLoad",
                "Authorization": `Bearer ${userToken}`
            }
        });

        document.getElementById("make").value = loadItemResponse.data.data[0].make;
        document.getElementById("model").value = loadItemResponse.data.data[0].model;
        document.getElementById("year").value = loadItemResponse.data.data[0].year;
        document.getElementById("vin").value = loadItemResponse.data.data[0].vin;
        document.getElementById("weight").value = loadItemResponse.data.data[0].weight;
        document.getElementById("length").value = loadItemResponse.data.data[0].length;
        document.getElementById("additionalInfo").value = loadItemResponse.data.data[0].additional_info;
        document.getElementById("carType").value = loadItemResponse.data.data[0].car_type_id;
        document.getElementById("isOperable").checked = loadItemResponse.data.data[0].is_operable;
    };

    const fetchDataWithRetry = async (url, retries = 3, delay = 1000) => {
        const userToken = localStorage.getItem('userToken');
        try {
            const response = await axios.get(url, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 429 && retries > 0) {
                console.log(`Retrying... (${retries} left)`);
                await new Promise(res => setTimeout(res, delay));
                return fetchDataWithRetry(url, retries - 1, delay * 2); // Backoff exponencial
            } else {
                console.error('Failed after retries:', error);
                throw error;
            }
        }
    };

    const getDrivers = useCallback(async () => {
        const driverData = await fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/driver`);
        const userData = await fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/users`);

        const mergedResponse = driverData.data.map((driver) => {
            return {
                ...userData.data.find((user) => user.id === driver.user_id),
                ...driver,
            };
        });

        setDrivers(mergedResponse);
    }, []);

    const getPartnerCompanies = useCallback(async () => {
        const partnerCompanyData = await fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/partnercompany`);
        setPartnerCompanies(partnerCompanyData.data);
    }, []);

    const getShippers = useCallback(async () => {
        const shipperData = await fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/shipper`);
        setShippers(shipperData.data);
    }, []);

    const getCarTypes = useCallback(async () => {
        const carTypeData = await fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/cartype`);
        setCartypes(carTypeData.data);
    }, []);

    const getTransports = useCallback(async () => {
        try {
            setLoading(true);

            const [loadTransportsRes, driversRes, partnerCompaniesRes, loadStatusesRes, usersRes] = await Promise.all([
                fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/loadtransport`),
                fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/driver`),
                fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/partnercompany`),
                fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/loadstatus`),
                fetchDataWithRetry(`${process.env.REACT_APP_API_PREFIX}/users`)
            ]);

            const drivers = Object.fromEntries(driversRes.data.map(d => [d.id, d]));
            const partnerCompanies = Object.fromEntries(partnerCompaniesRes.data.map(pc => [pc.id, pc]));
            const loadStatuses = Object.fromEntries(loadStatusesRes.data.map(ls => [ls.id, ls]));
            const users = Object.fromEntries(usersRes.data.map(u => [u.id, u]));

            const mergedTransports = loadTransportsRes.data.map(lt => {
                return {
                    ...lt,
                    driver: drivers[lt.driver_id],
                    partner_company: partnerCompanies[lt.partner_company_id],
                    load_status: loadStatuses[lt.load_status_id],
                    user: lt.driver_id ? users[drivers[lt.driver_id].user_id] : ''
                };
            });

            const sortedTransports = mergedTransports.sort((a, b) => new Date(b.dispatch_date) - new Date(a.dispatch_date));

            setTransports(sortedTransports);
        } catch (error) {
            console.error("Erro ao obter os dados: ", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    const applyFilters = useCallback(() => {
        let filtered = transports.filter(transport => {
            const statusMatch = filters.status ? transport.load_status && transport.load_status.name && transport.load_status.name.toLowerCase().includes(filters.status.toLowerCase()) : true;
            const driverMatch = filters.driver ? transport.driver && transport.driver.name && transport.driver.name.toLowerCase().includes(filters.driver.toLowerCase()) : true;
            const orderIdMatch = filters.orderId ? transport.order_id && transport.order_id.toLowerCase().includes(filters.orderId.toLowerCase()) : true;
            const vehicleMatch = filters.vehicle ? transport.make && transport.make.toLowerCase().includes(filters.vehicle.toLowerCase()) : true;
            const idMatch = filters.id ? transport.id && transport.id.toString().includes(filters.id) : true;
            return statusMatch && driverMatch && orderIdMatch && vehicleMatch && idMatch;
        });

        if (filters.orderBy) {
            filtered.sort((a, b) => {
                if (filters.sort === 'asc') {
                    return a[filters.orderBy] > b[filters.orderBy] ? 1 : -1;
                } else {
                    return a[filters.orderBy] < b[filters.orderBy] ? 1 : -1;
                }
            });
        }

        setFilteredTransports(filtered);
    }, [filters, transports]);

    useEffect(() => {
        applyFilters();
    }, [filters, transports, applyFilters]);

    useEffect(() => {
        getDrivers();
        getPartnerCompanies();
        getShippers();
        getCarTypes();
        getTransports();
    }, [getDrivers, getPartnerCompanies, getShippers, getCarTypes, getTransports]);

    return (
        <div className="App w-full min-h-screen bg-gray-100">
            <ScrollToTopButton />
            <div className="filter-navbar py-4 px-6 bg-white shadow-md">
                <div className="flex gap-4 mb-4">
                    <input
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                        placeholder="Search by ID"
                    />

                    <input
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        name="orderId"
                        value={filters.orderId}
                        onChange={handleFilterChange}
                        placeholder="Search by Order ID"
                    />
                    {!isMobile && (
                        <>
                            <input
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                                name="vehicle"
                                value={filters.vehicle}
                                onChange={handleFilterChange}
                                placeholder="Search by Vehicle"
                            />
                            <select
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                                name="orderBy"
                                value={filters.orderBy}
                                onChange={handleFilterChange}
                            >
                                <option value="dispatch_date">Order By Date</option>
                                <option value="payment_rate">Order By Rate</option>
                            </select>
                        </>

                    )}

                    <button
                        className="p-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none"
                        onClick={applyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <Spinner />
                </div>
            ) : (
                <div className="content px-6 py-4">
                    <div className="p-10 grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {filteredTransports.map((transportData) => (
                            <TransportCard key={transportData.id} transport={transportData} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default LoadTransportPage;