import React from 'react';
import { FaTruck, FaDollarSign, FaMapMarkedAlt } from 'react-icons/fa'; // Ensure you have these icons installed

export default function DashboardDriver() {
    return (
        <div className=" min-h-screen">

            <div className="bg-orange-500 px-3 md:px-8 h-40" />
            <div className="px-3 md:px-8 -mt-10">
                <div style={{ marginTop: '-7rem' }} className="container mx-auto max-w-full text-center">
                    <h1 className="text-white text-4xl md:text-5xl font-bold drop-shadow-lg">Driver, welcome to Cajuu.app</h1>
                    <p className="text-white text-xl md:text-2xl mt-2 animate-pulse">Your logistics partner company</p>
                </div>
            </div>

            <div className="px-3 md:px-8 mt-10">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-5">

                    </div>
                </div>
            </div>

            {/* Dashboard Content */}
            <div className="container mx-auto px-4 sm:px-8 mt-24">
                <div className="flex flex-wrap justify-center gap-4">
                    {/* Status Cards */}
                    <div className="bg-white p-5 rounded-xl shadow-lg flex flex-col items-center justify-between hover:scale-105 transition-transform duration-300 ease-in-out">
                        <div>
                            <p className="text-gray-500 text-sm">Active Rides</p>
                            <p className="text-lg font-semibold">34</p>
                        </div>
                        <FaTruck className="text-indigo-500 text-3xl" />
                    </div>
                    <div className="bg-white p-5 rounded-xl shadow-lg flex flex-col items-center justify-between hover:scale-105 transition-transform duration-300 ease-in-out">
                        <div>
                            <p className="text-gray-500 text-sm">Total Earnings</p>
                            <p className="text-lg font-semibold">$5,230</p>
                        </div>
                        <FaDollarSign className="text-green-500 text-3xl" />
                    </div>
                    <div className="bg-white p-5 rounded-xl shadow-lg flex flex-col items-center justify-between hover:scale-105 transition-transform duration-300 ease-in-out">
                        <div>
                            <p className="text-gray-500 text-sm">Total Distance</p>
                            <p className="text-lg font-semibold">1,200 mi</p>
                        </div>
                        <FaMapMarkedAlt className="text-red-500 text-3xl" />
                    </div>
                </div>
            </div>
        </div>
    );
}
