import axios from "axios";
import React, { useEffect, useState } from "react";
//import { toast } from "react-toastify";
import { Button } from "@material-tailwind/react";
import Card from "@material-tailwind/react/Card";

function DriverPage() {
  // variables to store data
  const [users, setUsers] = useState([]);
  const [partnerCompanies, setPartnerCompanies] = useState([]);

  // get users using axios from api
  const getUsers = async () => {
    // axios code here
    const userResponse = await axios.get("https://new.cajuu.app/public/api/users");

    // set users in state
    setUsers(userResponse.data.data);
  };

  // get partner companies
  const getPartnerCompanies = async () => {
    // axios code here
    const partnerCompanyResponse = await axios.get(
      "https://new.cajuu.app/public/api/partnercompany"
    );

    // set partner companies in state
    setPartnerCompanies(partnerCompanyResponse.data.data);
  };

  useEffect(() => {
    getUsers();
    getPartnerCompanies();
  }, []);

  // get data from form
  const submitForm = async (e) => {
    e.preventDefault();

    // get all values of the form
    const formData = new FormData(e.target);

    // validate all inputs
    for (let [key, value] of formData.entries()) {
      if (value === "") {
        alert(`${key} is empty`);
        return;
      }
    }

    // get all data from form by destructuring
    const {
      users,
      trailer_type,
      income_type,
      partner_company,
      trailer_width,
      trailer_length,
      trailer_height,
      trailer_combination,
    } = Object.fromEntries(formData.entries());

    // create payload
    const payload = {
      user_id: users,
      trailer_type: trailer_type,
      income_type: income_type,
      partner_company_id: partner_company,
      trailer_width: trailer_width,
      trailer_length: trailer_length,
      trailer_height: trailer_height,
      trailer_weight_tare: trailer_combination,
      signature_path: "test",
    };

    // post data to api
    // const response = await axios.post('http://127.0.0.1:8000/api/driver', payload);

    // console.log(response.data);

    // try catch
    try {
      const response = await axios.post(
        "https://new.cajuu.app/public/api/driver",
        payload
      );
      console.log(response.data);
      //toast.success("Driver added successfully");
    } catch (error) {
      console.log(error.response.data);
      //toast.error("Error adding driver");
    }

    // reset form
    e.target.reset();
  };

  return (
    <div className="px-4">
      <Card className="p-2 mt-4">
        <form className="flex flex-col" onSubmit={submitForm}>
          <div className="flex w-full flex-col p-10 border-1">

            <div className="mt-5">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                <div className="flex flex-col w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">User</label>
                  <select
                    name="users"
                    className="block w-full px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">Select User</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.first_name} {user.last_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">Trailer Type</label>
                  <select
                    name="trailer_type"
                    className="block w-full px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option value="">Select Trailer Type</option>
                    <option value="Flatbed">Flatbed</option>
                    <option value="Stepdeck">Stepdeck</option>
                    <option value="Reefer">Reefer</option>
                    <option value="Van">Van</option>
                    <option value="Hot Shot">Hot Shot</option>
                    <option value="Power Only">Power Only</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="flex flex-col w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">Income Type</label>
                  <select name="income_type" className="block w-full px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option value="">Select Income Type</option>
                    <option value="Percentage">Percentage</option>
                    <option value="Mileage">Mileage</option>
                    <option value="Hourly">Hourly</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="flex flex-col w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">Partner Company</label>
                  <select
                    name="partner_company"
                    className="block w-full px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option value="">Select Partner Company</option>
                    {partnerCompanies.map((partnerCompany) => (
                      <option
                        key={partnerCompany.id}
                        value={partnerCompany.id}
                      >
                        {partnerCompany.partner_company_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                <div className="flex flex-col w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">Trailer Width</label>
                  <input
                    type="number"
                    name="trailer_width"
                    placeholder="Trailer Width"
                    autoComplete="given-name"
                    className="block w-full px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="flex flex-col w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">Trailer Length</label>
                  <input
                    type="number"
                    name="trailer_length"
                    placeholder="Trailer Length"
                    className="block w-full px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="flex flex-col w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">Trailer Height</label>
                  <input
                    type="number"
                    name="trailer_height"
                    placeholder="Trailer Height"
                    className="block w-full px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="flex flex-col w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">Trailer Combination</label>
                  <input
                    type="number"
                    name="trailer_combination"
                    placeholder="Trailer Combination"
                    className="block w-full px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end mt-5">
              <Button type="submit" className="mx-2 mr-2" style={{ backgroundColor: 'orange', marginTop: '0rem'  }}>
                Submit
              </Button>
              <Button type="reset" className="mx-2 mr-2" style={{ backgroundColor: 'red', marginTop: '0rem'  }}>
                Reset
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default DriverPage;
