import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import MainSpinner from '../components/MainSpinner'; // Certifique-se de ajustar o caminho conforme necessário

const StaffInvitation = () => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('1');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula carregamento
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Ajuste o tempo conforme necessário

    return () => clearTimeout(timer);
  }, []);

  const handleInvite = async (e) => {
    // Lógica de convite aqui
    console.log('Inviting:', { email, role });

    const payload = {
      user_role_id: role,
      email: email,
    };

    try {
      const userToken = localStorage.getItem('userToken');

      const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/partnercompany/invite_staff`,
        payload,
        {
          headers: {
            "Screen-Name": "/PartnerCompany",
            "Authorization": `Bearer ${userToken}`
          }
        });
      console.log("Formulário enviado com sucesso:", response.data);
      // Mostra um alerta de sucesso
      Swal.fire({
        title: 'Success!',
        text: 'Form sent successfully!',
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then(() => {
        //setFormData(initialFormData);
      });
    } catch (error) {
      console.error("There was an error submitting the form:", error);

      let errorMessage = 'Request failed';
      if (error.response && error.response.data) {
        errorMessage = `${error.response.data.message} - ${error.response.data.error}`;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    } finally {
      //setLoading(false); // End loading
    }
  };

  if (loading) {
    return <MainSpinner />;
  }

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100" style={{marginTop:'-9rem'}}>
      <div className="w-96 p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-6 text-center">Staff Invitation</h2>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder='john@cajuu.com'
          />
        </div>
        <div className="mb-4">
          <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role:</label>
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="1">Admin</option>
            <option value="2" selected="selected">Driver</option>
            <option value="7">Account Dispatcher</option>
          </select>
        </div>
        <button
          onClick={handleInvite}
          className="w-full py-2 mt-4 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
        >
          Invite
        </button>
      </div>
    </div>
  );
};

export default StaffInvitation;