import React, { useState, useCallback } from 'react';
import { GoogleMap, DirectionsRenderer, LoadScript } from '@react-google-maps/api';
import Spinner from '../components/Spinner';

const ZipcodeMap = () => {
  const [originZip, setOriginZip] = useState('');
  const [destinationZip, setDestinationZip] = useState('');
  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const geocodeZip = useCallback(async (zip) => {
    setLoading(true);
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
    const data = await response.json();
    setLoading(false);
    return data.results[0].geometry.location;
  }, []);

  const fetchRoute = useCallback(async (origin, destination) => {
    setLoading(true);
    if (window.google) {
      const DirectionsService = new window.google.maps.DirectionsService();
      DirectionsService.route({
        origin: new window.google.maps.LatLng(origin.lat, origin.lng),
        destination: new window.google.maps.LatLng(destination.lat, destination.lng),
        travelMode: window.google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
          setDistance(result.routes[0].legs[0].distance.text);
          setDuration(result.routes[0].legs[0].duration.text);
        } else {
          console.error(`error fetching directions ${result}`);
          setErrorMessage("Failed to fetch directions. Please try again.");
        }
        setLoading(false);
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    if (!originZip || !destinationZip) {
      setErrorMessage("Please fill in both the origin and destination fields.");
      return;
    }
    const originCoords = await geocodeZip(originZip);
    const destinationCoords = await geocodeZip(destinationZip);
    await fetchRoute(originCoords, destinationCoords);
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <form onSubmit={handleSubmit} className="flex flex-col md:flex-row md:items-center gap-4 p-4">
        <InputField
          label="Origin:"
          value={originZip}
          onChange={setOriginZip}
          placeholder="Enter zipcode, or city/state"
        />
        <InputField
          label="Destination:"
          value={destinationZip}
          onChange={setDestinationZip}
          placeholder="Enter zipcode, or city/state"
        />
        <SubmitButton />
      </form>
      <div className="relative">
        {loading && <Spinner />}
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <div className="absolute top-0 left-0 right-0 bottom-0">
          <MapContainer directions={directions} />
        </div>
        {!loading && distance && duration && <RouteInfo distance={distance} duration={duration} />}
      </div>
    </div>
  );
};

const InputField = ({ label, value, onChange, placeholder }) => (
  <div className="flex flex-col">
    <label className="mb-1 text-sm font-semibold">{label}</label>
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="form-input px-4 py-2 border border-gray-300 rounded-md"
    />
  </div>
);

const SubmitButton = ({ label }) => (
  <div className="flex flex-col mt-5">
    <label className="mb-1 text-sm font-semibold"></label>
    <button type="submit" className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600">
      Show Route
    </button>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
    {message}
  </div>
);

const RouteInfo = ({ distance, duration }) => (
  <div className="p-4 bg-white rounded-lg shadow-md text-center">
    <p className="text-lg font-semibold">Distance: <span className="text-blue-500">{distance}</span></p>
    <p className="text-lg font-semibold">Duration: <span className="text-blue-500">{duration}</span></p>
  </div>
);

const MapContainer = ({ directions }) => (
  <div className="w-full h-96 md:h-[500px] lg:h-[600px] rounded-xl overflow-hidden">
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={{ height: "100%", width: "100%" }}
        center={{ lat: 32.835831, lng: -83.634209 }}
        zoom={7}
      >
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    </LoadScript>
  </div>
);

export default ZipcodeMap;
