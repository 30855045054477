import React, { useState, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';
import usePreviousRoute from '../hooks/UsePreviousRoute';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const previousPath = usePreviousRoute();
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAllowed, setIsAllowed] = useState(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const checkAuth = () => {
            const token = localStorage.getItem('userToken');
            if (!token) {
                return { authenticated: false, authorized: false };
            }

            try {
                const decoded = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                if (decoded.exp < currentTime) {
                    return { authenticated: false, authorized: false };
                }

                //const screenName = location.pathname.substring(1); // Remove a barra inicial do pathname
                const screenName = location.pathname; // Remove a barra inicial do pathname
                const hasPermission = decoded.role_permissions.some(permission => permission.screen_name === screenName && permission.can_read);
                console.log('location.pathname: ' + location.pathname);
                console.log('All Permissions: ' + JSON.stringify(decoded.role_permissions));
                //const authorized = roles ? roles.includes(decoded.user_role_id) : true;
                return { authenticated: true, authorized: hasPermission };
            } catch (error) {
                return { authenticated: false, authorized: false };
            }
        };

        const { authenticated, authorized } = checkAuth();

        setIsAuthenticated(authenticated);

        if (!authenticated) {
            setIsAllowed(false);
            setChecked(true);
        } else if (authenticated && !authorized) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access this page.',
            }).then(() => {
                // Quando a modal for fechada
                setChecked(true);
                setIsAllowed(false);
            });
        } else {
            setIsAllowed(true);
            setChecked(true);
        }
    }, [location]);

    // Enquanto não checar a autorização, não renderiza nada ou renderiza algo como um loader
    if (!checked) {
        return null; // ou um componente de carregamento
    }

    if (!isAuthenticated) {
        return (<Redirect to={{ pathname: '/Login', state: { from: location } }} />); // ou um componente de carregamento
    }

    return isAllowed ? (
        <Route {...rest} render={(props) => <Component {...props} />} />
    ) : (
        <Redirect to={{ pathname: previousPath, state: { from: location } }} />
    );
};


    /*
    <Redirect to={{ pathname: '/', state: { from: location } }} /> // ou qualquer outra rota que você considerar como "home" ou página de segurança

    const checkAuth = () => {
    const token = localStorage.getItem('userToken');
    if (!token) {
        return { authenticated: false, authorized: false };
    }

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            return { authenticated: false, authorized: false };
        }

        const authorized = roles ? roles.includes(decoded.user_role_id) : true;
        return { authenticated: true, authorized };
    } catch (error) {
        return { authenticated: false, authorized: false };
    }
};

const { authenticated, authorized } = checkAuth();

if (!authenticated) {
    return <Redirect to={{ pathname: '/Login', state: { from: location } }} />;
} else if (authenticated && !authorized) {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You do not have permission to access this page.',
    });
    // Não realiza o redirecionamento; mantém o usuário na mesma página
    return <Component {...rest} />;
} else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
}
};
*/
export default PrivateRoute;