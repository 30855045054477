export default function Footer() {
    return (
        <footer className="py-4 sm:py-6 px-4 sm:px-16 border-t border-gray-200 text-xs sm:text-sm md:text-base font-light flex flex-col items-center lg:flex-row justify-center space-y-4 lg:space-y-0 lg:space-x-4">
            <p className="text-white mb-6 lg:mb-0 text-center lg:text-left" style={{ textShadow:'0px 0px 10px black'}}>
                {/* Copyright &copy; {new Date().getFullYear()} - {' '} */}
                Copyright &copy; {new Date().getFullYear()} -
                <a
                    href="https://cajuu.app/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white-500"
                >
                    Cajuu™
                </a>
            </p>
        </footer>
    );
}
