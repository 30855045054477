import React, { useState, useEffect } from 'react';
import { TbTrashX } from 'react-icons/tb';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const ReleaseModal = ({ isOpen, onClose, idLoadTransport, loadItems, selectedLoadItemId }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedLoadItem, setSelectedLoadItem] = useState(null);
    const [loadItemId, setLoadItemId] = useState(selectedLoadItemId || ""); // Inicializar como string vazia
    const history = useHistory();

    const handleFileSelect = (event) => {
        const filesWithPreview = Array.from(event.target.files).map(file => ({
            file,
            name: file.name,
            preview: URL.createObjectURL(file)
        }));

        setSelectedFiles(currentFiles => [...currentFiles, ...filesWithPreview]);
    };

    useEffect(() => {
        if (loadItems.length === 1) {
            setSelectedLoadItem(loadItems[0]);
            setLoadItemId(loadItems[0].id.toString());
        }
    }, [loadItems]);

    const handleSendFiles = async () => {
        let errored = false;

        // console.log('Verificando seleção do veículo');
        // console.log('selectedLoadItem:', selectedLoadItem);

        if (!selectedLoadItem) {
            Swal.fire({
                title: 'Erro!',
                text: 'Por favor, selecione um veículo antes de enviar os arquivos.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }

        for (const { file } of selectedFiles) {
            if (file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                const formData = new FormData();
                formData.append('document', file);
                formData.append('make', selectedLoadItem.make); // Adiciona a marca do veículo
                formData.append('model', selectedLoadItem.model); // Adiciona o modelo do veículo
                formData.append('year', selectedLoadItem.year); // Adiciona o ano do veículo
                const userToken = localStorage.getItem('userToken');
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/loaditem/gate_pass/${selectedLoadItem.id}`, formData, {
                        headers: {
                            "Screen-Name": "/Load",
                            "Authorization": `Bearer ${userToken}`,
                            "Content-Type": "multipart/form-data"
                        }
                    });

                    console.log(`${file.name} enviado com sucesso:`, response.data);
                } catch (error) {
                    console.error(`Erro ao adicionar o Others PDF ${file.name}:`, error);
                    errored = true;
                    Swal.fire({
                        title: 'Erro!',
                        text: `Falha ao enviar ${file.name}.`,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                    break;
                }
            } else {
                alert('Please upload only PDF, DOC, or DOCX files.');
                errored = true;
                break;
            }
        }

        if (!errored) {
            Swal.fire({
                title: 'Sucesso!',
                text: 'Todos os arquivos foram enviados com sucesso!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                onClose();
                history.push('/Load');
            });
        }

        selectedFiles.forEach(fileObj => URL.revokeObjectURL(fileObj.preview));
        setSelectedFiles([]);
    };

    const removeSelectedFile = (index) => {
        URL.revokeObjectURL(selectedFiles[index].preview);
        setSelectedFiles(currentFiles => currentFiles.filter((_, i) => i !== index));
    };

    const handleChange = (e) => {
        const selectedItemId = e.target.value;
        setLoadItemId(selectedItemId);
        const selectedItem = loadItems.find(item => item.id.toString() === selectedItemId);
        setSelectedLoadItem(selectedItem);
        console.log('Item selecionado:', selectedItem); // Adiciona log para depuração
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex justify-center items-center z-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-content bg-white rounded-lg shadow-xl w-11/12 md:w-3/4 overflow-y-auto" onClick={e => e.stopPropagation()} style={{ maxHeight: '90vh' }}>
                <div className="modal-header flex justify-between items-center px-4 py-2 bg-gray-100">
                    <h5 className="modal-title text-xl font-semibold text-gray-700">Upload Release Pdf</h5>
                    <select
                        value={loadItemId}
                        onChange={handleChange}
                        className="w-80 p-2 border rounded"
                    >
                        <option value="">Selecione um Carro</option>
                        {loadItems && loadItems.map((loadItem) => (
                            <option key={loadItem.id} value={loadItem.id.toString()}>
                                {loadItem.make} {loadItem.model} - {loadItem.year}
                            </option>
                        ))}
                    </select>
                    <button className="close text-gray-500 hover:text-gray-700" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="modal-body p-4">
                    <div className="mb-4">

                    </div>
                    <div className="flex flex-wrap justify-center items-center">
                        <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <p className="mb-2 text-sm text-gray-500">
                                    <span className="font-semibold">Click to upload</span> or drag 'n' drop PDF here
                                </p>
                                <p className="text-xs text-gray-500">
                                    PDF files (up to 10MB)
                                </p>
                            </div>
                            <input
                                id="dropzone-file"
                                type="file"
                                className="hidden"
                                onChange={handleFileSelect}
                                accept="application/pdf"
                            />
                        </label>
                    </div>
                    <div className="flex flex-wrap">
                        {selectedFiles.map((fileObj, index) => (
                            <div key={index} className="p-4 relative">
                                <iframe src={fileObj.preview} className="w-full h-64" title="PDF Preview"></iframe>
                                <button
                                    onClick={() => removeSelectedFile(index)}
                                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                                >
                                    <TbTrashX />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-end px-4 py-3 bg-gray-50 text-right border-t border-gray-200 space-x-2">
                    <button className="py-2 px-4 text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200" onClick={onClose}>Cancel</button>
                    <button className="py-2 px-4 text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200" onClick={handleSendFiles}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default ReleaseModal;