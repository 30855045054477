import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Input from '@material-tailwind/react/Input';
import spinner from './Spinner'; // Certifique-se de ter um componente de spinner

export default function SettingsForm({ transport }) {
    const [userData, setUserData] = useState(transport || {});
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const getUserDetailsFromToken = async () => {
            const token = localStorage.getItem('userToken');
            if (token) {
                const decoded = jwtDecode(token);
                const userId = decoded.user_id;

                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/users/${userId}`, {
                        headers: {
                            "Screen-Name": "/Profile",
                            "Authorization": `Bearer ${token}`
                        }
                    });

                    if (response.data && response.data.data) {
                        const user = response.data.data;
                        const newUserData = {
                            id: user.id,
                            username: user.username,
                            email: user.email,
                            first_name: user.first_name,
                            last_name: user.last_name,
                            phone: user.phone,
                            profilePicturePath: user.profile_picture_path,
                            userRoleId: user.user_role_id,
                            partnerCompanyId: user.partner_company_id,
                            createdAt: user.created_at,
                            updatedAt: user.updated_at
                        };
                        setUserData(newUserData);
                    } else {
                        throw new Error('Dados do usuário não encontrados na resposta da API');
                    }
                } catch (error) {
                    setError("Erro ao buscar dados do usuário: " + error.message);
                }
            } else {
                setError("Token do usuário não encontrado");
            }
            setIsLoadingData(false);
        };

        getUserDetailsFromToken();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const userToken = localStorage.getItem('userToken');
            if (!userData.id) {
                throw new Error("ID do usuário não encontrado");
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_PREFIX}/users/${userData.id}`,
                userData,
                {
                    headers: {
                        "Screen-Name": "/Profile",
                        "Authorization": `Bearer ${userToken}`,
                    }
                }
            );

            if (response.status === 200) {
                setUserData(response.data.data);

                Swal.fire({
                    title: 'Success!',
                    text: 'Profile updated successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        history.push('/Profile');
                    }
                });
            } else {
                setError("Erro ao atualizar os dados do usuário");
            }
        } catch (error) {
            setError("Erro ao enviar dados: " + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoadingData) {
        return <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 mb-6 border-b-2 border-orange-500"></div>
        </div>;
    }

    return (
        <Card>
            <CardHeader color="orange" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-2xl">My Account</h2>
                </div>
            </CardHeader>
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                        User Information
                    </h6>
                    <div className="flex flex-wrap mt-10">
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Username"
                                name="username"
                                value={userData.username || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <Input
                                type="email"
                                color="purple"
                                placeholder="Email"
                                name="email"
                                value={userData.email || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="First Name"
                                name="first_name"
                                value={userData.first_name || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Last Name"
                                name="last_name"
                                value={userData.last_name || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Phone"
                                name="phone"
                                value={userData.phone || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        {error && <p className="text-red-500 mt-4">{error}</p>}
                        <div className="flex justify-end w-full mt-8">
                            <Button
                                color="orange"
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save Changes'}
                            </Button>
                        </div>
                    </div>
                </form>
            </CardBody>
        </Card>
    );
}
