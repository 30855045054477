import React, { useState } from 'react';
import { FaCar, FaMapMarkerAlt, FaDollarSign } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Modal from './quotemodal';
import axios from 'axios';
import '../components/css/Quote.css'

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
  return new Date(dateString).toLocaleDateString('en-US', options) + ' ' + new Date(dateString).toLocaleTimeString('en-US', options);
};

const VehicleDetails = ({ vehicle }) => (
  <div className="flex justify-between mt-2 items-center">
    <div className="flex flex-1 items-center text-gray-900 text-sm font-medium">
      {vehicle.year} {vehicle.make} {vehicle.model}
    </div>
    <p className={`text-sm font-semibold ${vehicle.operable === 1 ? 'text-green-600' : 'text-red-600'}`}>
      {vehicle.operable === 1 ? 'Operable' : 'Non-Operable'}
    </p>
  </div>
);

const QuoteCard = ({ quote }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [paymentRate, setPaymentRate] = useState(quote.payment_rate);
  const [updatedQuote, setUpdatedQuote] = useState(quote);

  const handlePaymentChange = (e) => {
    setPaymentRate(e.target.value);
  };

  const handleSaveAndSend = async () => {

    const payload = {
      payment_rate: parseFloat(paymentRate),
    };

    try {
      const userToken = localStorage.getItem('userToken');
      const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/quote/${quote.id}`,
        payload,
        {
          headers: {
            'Screen-Name': '/Quotes',
            'Authorization': `Bearer ${userToken}`
          }
        });

      if (response.status !== 200) {
        throw new Error('Failed to update the quote');
      }

      //const data = await response.json();
      console.log('Update successful:', response.data);
      setUpdatedQuote({ ...updatedQuote, payment_rate: paymentRate });
      setModalOpen(false);
      await Swal.fire({
        title: 'Success!',
        text: 'Quote updated successfully!',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    } catch (error) {
      console.error("There was an error updating the quote:", error);
      await Swal.fire({
        title: 'Error!',
        text: 'Failed to update the quote.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };


  const handleArchiveLoad = async () => {
    try {
      const userToken = localStorage.getItem('userToken');
      const response = await axios.delete(`${process.env.REACT_APP_API_PREFIX}/quote/${quote.id}`,
        {
          headers: {
            'Screen-Name': '/NewQuotes',
            'Authorization': `Bearer ${userToken}`
          }
        });

      if (response.status !== 200) {
        throw new Error('Failed to delete the quote');
      }

      console.log('Delete successful:', response.data);
      await Swal.fire({
        title: 'Deleted!',
        text: 'Quote has been Archived successfully!',
        icon: 'success',
        timer: 4000,
        timerProgressBar: true,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("There was an error deleting the quote:", error);
      await Swal.fire({
        title: 'Error!',
        text: 'Failed to delete the quote.',
        icon: 'error',
        timer: 4000,
        timerProgressBar: true,
      }).then(() => {
        window.location.reload();
      });
    }
  };


  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden border-l-8 border-orange-500 p-4">
      <div onClick={() => setModalOpen(true)} className="cursor-pointer">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
            <FaCar className="text-orange-500 mr-2" />Vehicle Information
          </h3>
          <span className="bg-orange-500 text-white text-sm font-semibold px-3 py-1 rounded-full">ID: {updatedQuote.id}</span>
        </div>

        {updatedQuote.quote_vehicles && updatedQuote.quote_vehicles.length > 0 && updatedQuote.quote_vehicles.map((vehicle, index) => (
          <VehicleDetails key={index} vehicle={vehicle} />
        ))}

        <div className="mt-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-green-500 mr-2" />
              <p className="text-gray-600 text-sm">
                {updatedQuote.pickup_city}, {updatedQuote.pickup_state}
              </p>
            </div>
            <div className="flex items-center">
              <p className="text-gray-600 text-sm font-semibold">${updatedQuote.payment_rate}</p>
            </div>
          </div>
          <div className="flex items-center justify-between mt-2">
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-red-500 mr-2" />
              <p className="text-gray-600 text-sm">
                {updatedQuote.delivery_city}, {updatedQuote.delivery_state}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 shadow-lg rounded-md bg-white Quote-mobile" style={{ width: '49%', marginTop: '-2rem' }}>
            {updatedQuote.quote_vehicles && updatedQuote.quote_vehicles.length > 0 && updatedQuote.quote_vehicles.map((vehicle, index) => (
              <div key={index} className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4 p-4 bg-white rounded-lg shadow-xl mb-4">
                <div className="w-full flex items-center justify-center space-x-2">
                  <h3 className="text-lg font-semibold text-gray-800 text-center">
                    {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                  </h3>
                  <span className="text-lg font-semibold py-2 px-4 rounded-md">
                    (
                    <span className={`${vehicle.operable === 1 ? 'text-green-800' : 'text-red-800'}`}>
                      {vehicle.operable === 1 ? 'OP' : 'INOP'}
                    </span>
                    )
                  </span>
                </div>
              </div>
            ))}

            <div className="mt-3 px-6">
              <form className="space-y-4">
                <div className="flex justify-between items-center space-x-3">
                  <div className="w-1/2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Name:
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        style={{ background: 'whitesmoke' }}
                        value={updatedQuote.name}
                        type="text"
                        readOnly
                      />
                    </label>
                  </div>
                  <div className="w-1/2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Phone:
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        style={{ background: 'whitesmoke' }}
                        value={updatedQuote.phone}
                        type="text"
                        readOnly
                      />
                    </label>
                  </div>
                </div>
                <div className="flex justify-between items-center space-x-3">
                  <div className="w-1/2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Pickup Location:
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        style={{ background: 'whitesmoke' }}
                        value={`${updatedQuote.pickup_city}, ${updatedQuote.pickup_state}`}
                        type="text"
                        readOnly
                      />
                    </label>
                  </div>
                  <div className="w-1/2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Delivery Location:
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        style={{ background: 'whitesmoke' }}
                        value={`${updatedQuote.delivery_city}, ${updatedQuote.delivery_state}`}
                        type="text"
                        readOnly
                      />
                    </label>
                  </div>
                </div>

                {updatedQuote.quote_vehicles && updatedQuote.quote_vehicles.length > 0 && updatedQuote.quote_vehicles.map((vehicle, index) => (
                  <div className="flex justify-between items-center space-x-3" key={index}>
                    {index === 0 && (
                      <>
                        <div className="w-1/2">
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            Type:
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                              style={{ background: 'whitesmoke' }}
                              value={updatedQuote.transport_type === 'Open' ? 'Open' : 'Enclosed'}
                              type="text"
                              readOnly
                            />
                          </label>
                        </div>
                        <div className="w-1/2">
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            E-mail:
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                              style={{ background: 'whitesmoke' }}
                              value={updatedQuote.email}
                              type="text"
                              readOnly
                            />
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                ))}


                <div className="flex justify-center items-center">
                  <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="quoteValuation">
                    Quote Valuation:
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-orange-600 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="quoteValuation"
                      type="number"
                      value={paymentRate}
                      onChange={handlePaymentChange}
                      placeholder="$999,99"
                    />
                  </label>
                </div>

                {/* <div className="flex justify-between items-center pt-4">
                  <button
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleSaveAndSend}
                  >
                    Send
                  </button>
                  <button
                    className="bg-transparent hover:bg-gray-200 text-gray-800 font-semibold py-2 px-8 border border-gray-400 rounded"
                    type="button"
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div> */}
                <div className="flex justify-between items-center pt-4">
                  <button
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleSaveAndSend}
                  >
                    Send
                  </button>
                  <button
                    className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleArchiveLoad}
                  >
                    Archived
                  </button>
                  <span className="bg-orange-500 text-white text-sm font-semibold px-3 py-1 rounded-full">ID: {updatedQuote.id}</span>
                  <button
                    className="bg-transparent hover:bg-gray-200 text-gray-800 font-semibold py-2 px-8 border border-gray-400 rounded"
                    type="button"
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QuoteCard;
