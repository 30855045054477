import React, { useState } from 'react';
import axios from 'axios';

const SendDocumentModal = ({ isOpen, onClose, onSend, transportId }) => {
  const [documentType, setDocumentType] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    onSend({
      documentType,
      customerEmail
    });
  };



  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-content bg-white rounded-lg shadow-xl overflow-hidden w-11/12 md:w-1/2 lg:w-1/3" onClick={e => e.stopPropagation()}>
        <div className="modal-header flex justify-between items-center px-4 py-2 border-b border-gray-200">
          <h5 className="modal-title text-lg font-medium text-gray-900">Send Document to Customer</h5>
          <button className="close text-gray-400 hover:text-gray-600" onClick={onClose}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <form onSubmit={handleSubmit} className="modal-body p-4">
          <div className="form-group mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Document:</label>
            <select className="form-control block w-full mt-1 border border-gray-300 bg-white shadow-sm text-gray-700 rounded-md focus:ring focus:ring-opacity-50 focus:ring-blue-300 focus:border-blue-300" value={documentType} onChange={(e) => setDocumentType(e.target.value)}>
              <option value="">Select a document type</option>
              <option value="pickup">Pickup</option>
              <option value="delivery">Delivery</option>
              <option value="invoice">Invoice</option>
              <option value="all">All available documents</option>
            </select>
          </div>
          <div className="form-group mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Customer Email:</label>
            <input type="email" className="form-control block w-full mt-1 border border-gray-300 bg-white shadow-sm text-gray-700 rounded-md focus:ring focus:ring-opacity-50 focus:ring-blue-300 focus:border-blue-300" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} placeholder="email@cajuu.com" />
          </div>
          <div className="modal-footer flex justify-end space-x-2 border-t border-gray-200 pt-4">
            <button type="button" className="py-2 px-4 text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200" onClick={onClose}>Close</button>
            <button type="submit" className="py-2 px-4 text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200">Send</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendDocumentModal;
