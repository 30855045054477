import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import './css/AdminNavBar.css';
import { jwtDecode } from 'jwt-decode'; // Correct import statement
import fallbackImage from '../assets/img/img-profile-error.png'; // Import the fallback image

export default function AdminNavbar({ showSidebar, setShowSidebar }) {
    const history = useHistory();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const [userDetails, setUserDetails] = useState({ name: '', email: '', picture: '', partner_company: '' });

    useEffect(() => {
        const getUserDetailsFromToken = () => {
            const token = localStorage.getItem('userToken');
            if (token) {
                const decoded = jwtDecode(token);
                setUserDetails({
                    name: decoded.first_name + " " + decoded.last_name,
                    email: decoded.email,
                    user_role: decoded.user_role_name,
                    picture: decoded.profile_picture_path,
                    partner_company: decoded.partner_company_name,
                });
            }
        };

        getUserDetailsFromToken();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSignOut = () => {
        localStorage.removeItem('userToken');
        history.push('/Login');
    };

    return (
        <nav className="bg-orange-500 md:ml-32 py-6 px-3 relative">
            <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
                <Button
                    className="menu-button"
                    color="transparent"
                    buttonType="link"
                    size="lg"
                    iconOnly
                    rounded
                    ripple="light"
                    onClick={toggleSidebar}
                >
                    <Icon name="menu" size="2xl" color="white" />
                </Button>

                <div className="flex justify-between items-center w-full">
                    <h4 className="uppercase text-white text-sm tracking-wider mt-1"></h4>

                    <div className="flex relative" ref={dropdownRef}>
                        <button
                            type="button"
                            className="flex items-center justify-center text-sm bg-white hover:bg-gray-200 rounded-full focus:outline-none focus:ring-4 focus:ring-orange-300 dark:focus:ring-orange-800 transition duration-300 ease-in-out w-10 h-10 overflow-hidden"
                            onClick={toggleDropdown}
                        >
                            <span className="sr-only">Open user menu</span>
                            <img
                                src={userDetails.picture ? `${process.env.REACT_APP_API_FILE_PREFIX}/${userDetails.picture}` : fallbackImage}
                                alt="User Photo"
                                className="w-full h-full object-cover rounded-full"
                                onError={(e) => { e.target.onerror = null; e.target.src = fallbackImage; }} // Configura a imagem padrão em caso de erro
                            />
                        </button>

                        {dropdownOpen && (
                            <div className='z-50 text-base list-none bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden absolute right-0 mt-2 w-48'>
                                <div className="px-4 py-3 bg-gray-100 dark:bg-gray-700">
                                    <span className="block text-sm font-semibold text-gray-900 dark:text-white">{userDetails.name} ({userDetails.user_role})</span>
                                    <span className="block text-sm text-gray-500 truncate dark:text-gray-400">{userDetails.email}</span>
                                </div>
                                <ul>
                                    <li className="text-lg">
                                        <NavLink
                                            to="/Profile"
                                            exact
                                            className="flex items-center gap-4 text-gray-800 font-medium px-6 py-3 hover:bg-orange-500 hover:text-white rounded-md transition duration-300 ease-in-out"
                                            activeClassName="bg-orange-700 text-white"
                                            onClick={closeDropdown}
                                        >
                                            <Icon name="settings" size="20" />
                                            Profile
                                        </NavLink>
                                    </li>

                                    <li className="text-lg">
                                        <NavLink
                                            to="/Dashboard"
                                            exact
                                            className="flex items-center gap-4 text-gray-800 font-medium px-6 py-3 hover:bg-orange-500 hover:text-white rounded-md transition duration-300 ease-in-out"
                                            activeClassName="bg-orange-700 text-white"
                                            onClick={closeDropdown}
                                        >
                                            <Icon name="dashboard" size="20" />
                                            Dashboard
                                        </NavLink>
                                    </li>

                                    <li className="text-lg">
                                        <NavLink
                                            to="/Login"
                                            exact
                                            className="flex items-center gap-4 text-gray-800 font-medium px-6 py-3 hover:bg-red-500 hover:text-white rounded-md transition duration-300 ease-in-out"
                                            activeClassName="bg-red-700 text-white"
                                            onClick={() => { closeDropdown(); handleSignOut(); }}
                                        >
                                            <Icon name="exit_to_app" size="20" />
                                            Sign out
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
}
