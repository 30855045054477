import React, { useState } from 'react';

const StaffInvitation = () => {
    const [role, setRole] = useState('Admin');

    const handleInvite = () => {
        // Lógica de convite aqui
        console.log('Inviting:', { role });
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100" style={{ marginTop: '-10rem' }}>
            <div className="w-96 p-8 bg-white rounded-lg shadow-lg">
                <a href="/" className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-12 h-12" src="./cajuu.png" alt="logo" />
                </a>
                <h2 className="text-xl font-bold mb-6 text-center">Super Admin</h2>
                <div className="mb-4">
                    <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role:</label>
                    <select
                        id="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="Admin">Admin</option>
                        <option value="User">JohnTest1</option>
                    </select>
                </div>
               
                <button
                    onClick={handleInvite}
                    className="w-full py-2 mt-4 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
                >
                    Select
                </button>
            </div>
        </div>
    );
};

export default StaffInvitation;
