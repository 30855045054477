import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import ReCAPTCHA from "react-google-recaptcha";
import '../components/css/RegisterForm.css';

Modal.setAppElement('#root');

const RegisterForm = ({ onBack, onSwitch }) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [userRole, setUserRole] = useState('customer');
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('userRoleId') || 3);
    const [isLoading, setIsLoading] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({});
    const [recaptchaValue, setRecaptchaValue] = useState(''); // Estado para armazenar o valor do reCAPTCHA
    const history = useHistory();

    const validateFields = () => {
        const errors = {};
        if (!email) errors.email = 'Email is required';
        if (!firstName) errors.firstName = 'First name is required';
        if (!lastName) errors.lastName = 'Last name is required';
        if (!phone) errors.phone = 'Phone number is required';
        if (!username) errors.username = 'Username is required';
        if (!password) errors.password = 'Password is required';
        if (password !== passwordConfirmation) errors.passwordConfirmation = 'Passwords do not match';
        if (!recaptchaValue) errors.recaptcha = 'Please complete the reCAPTCHA';
        return errors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const errors = validateFields();
        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            setIsLoading(false);
            return;
        }

        try {
            const partnerCompanyId = localStorage.getItem('companyId') || 1;
            const userRoleLocal = localStorage.getItem('userRoleId') || 3;

            const registerData = {
                email,
                first_name: firstName,
                last_name: lastName,
                phone,
                username,
                password,
                c_password: passwordConfirmation,
                recaptcha: recaptchaValue, // Incluindo o valor do reCAPTCHA no envio do formulário
                user_role_id: userRoleLocal,
                partner_company_id: partnerCompanyId,
            };

            console.log("Register Data:", JSON.stringify(registerData, null, 2));

            // await axios.post(`);

            const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/register`, registerData);


            console.log("Response from server:", response.data);

            localStorage.setItem('registrationSuccess', 'true');

            Swal.fire({
                title: 'Success!',
                text: 'Registration successful! Check your email to confirm.',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                window.location.reload();
            });

            localStorage.removeItem('companyId');
            localStorage.removeItem('userRoleId');

            clearFormFields();
        } catch (err) {
            console.error("Error during registration:", err);
            if (err.response && err.response.data) {
                const errorData = err.response.data;

                if (errorData.email) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'The email address is already in use. Please use a different email or try recovering your password.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: `Registration not completed! ${errorData.message}`,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Registration not completed! An unknown error occurred.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const clearFormFields = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
        setPhone('');
        setUsername('');
        setPassword('');
        setPasswordConfirmation('');
        setRecaptchaValue(''); // Limpar o valor do reCAPTCHA ao limpar o formulário
    };

    const onRecaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setRecaptchaValue(value); // Captura o valor do reCAPTCHA
    };

    return (
        <Modal isOpen={true} onRequestClose={onBack} contentLabel="User Registration" className="flex items-center justify-center min-h-screen">
            <div className="bg-white rounded-lg shadow-lg p-6 mx-4 md:max-w-4xl w-full overflow-auto custom-scrollbar" style={{ maxHeight: '94vh', maxWidth: '41rem' }}>
                <a href="#" className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-12 h-12" src="./cajuu.png" alt="logo" />
                </a>
                <h2 className="text-2xl font-bold text-center mb-6">User Registration</h2>
                <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`bg-gray-50 border ${fieldErrors.email ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                            placeholder="name@company.com"
                            required
                        />
                        {fieldErrors.email && <span className="text-red-500 text-sm">{fieldErrors.email}</span>}
                    </div>
                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.firstName ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                placeholder="First name"
                                required
                            />
                            {fieldErrors.firstName && <span className="text-red-500 text-sm">{fieldErrors.firstName}</span>}
                        </div>
                        <div className="w-1/2">
                            <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.lastName ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                placeholder="Last name"
                                required
                            />
                            {fieldErrors.lastName && <span className="text-red-500 text-sm">{fieldErrors.lastName}</span>}
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
                            <input
                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.phone ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                placeholder="+1 123 456 7890"
                                required
                            />
                            {fieldErrors.phone && <span className="text-red-500 text-sm">{fieldErrors.phone}</span>}
                        </div>
                        <div className="w-1/2">
                            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.username ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                placeholder="Username"
                                required
                            />
                            {fieldErrors.username && <span className="text-red-500 text-sm">{fieldErrors.username}</span>}
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.password ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                placeholder="Password"
                                required
                            />
                            {fieldErrors.password && <span className="text-red-500 text-sm">{fieldErrors.password}</span>}
                        </div>
                        <div className="w-1/2">
                            <label htmlFor="passwordConfirmation" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                            <input
                                type="password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.passwordConfirmation ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                placeholder="Confirm your password"
                                required
                            />
                            {fieldErrors.passwordConfirmation && <span className="text-red-500 text-sm">{fieldErrors.passwordConfirmation}</span>}
                        </div>
                    </div>

                    <ReCAPTCHA
                        sitekey="6Le8GfwpAAAAAD6ud1WiozXOqjy2cakJcezWIEN6"
                        onChange={onRecaptchaChange}
                        className="flex justify-center my-4"
                    />
                    {fieldErrors.recaptcha && <span className="text-red-500 text-sm">{fieldErrors.recaptcha}</span>}

                    <div className="flex justify-evenly mt-6">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2"
                        >
                            {isLoading ? 'Registering...' : 'Register'}
                        </button>
                        <button
                            type="button"
                            onClick={() => history.push('/Login')}
                            className="w-full text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ml-2"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default RegisterForm;
