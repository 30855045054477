import React, { useState, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import SignatureCanvas from 'react-signature-canvas';
import ReCAPTCHA from "react-google-recaptcha";
import '../components/css/RegisterForm.css';

const DriverRegisterForm = ({ onBack }) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [trailerType, setTrailerType] = useState('');
    const [signatureFile, setSignatureFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const [recaptchaValue, setRecaptchaValue] = useState(''); // Adicionando o estado do reCAPTCHA
    const history = useHistory();

    const sigCanvas = useRef({});

    const validateFields = () => {
        const errors = {};
        if (!email) errors.email = 'Email is required';
        if (!firstName) errors.firstName = 'First name is required';
        if (!lastName) errors.lastName = 'Last name is required';
        if (!phone) errors.phone = 'Phone number is required';
        if (!username) errors.username = 'Username is required';
        if (!password) errors.password = 'Password is required';
        if (password !== passwordConfirmation) errors.passwordConfirmation = 'Passwords do not match';
        if (!trailerType) errors.trailerType = 'Trailer type is required';
        if (!signatureFile) errors.signature = 'Signature is required';
        return errors;
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     setIsLoading(true);

    //     const errors = validateFields();
    //     if (Object.keys(errors).length > 0) {
    //         setFieldErrors(errors);
    //         setIsLoading(false);
    //         return;
    //     }

    //     if (!recaptchaValue) {
    //         setError("Please complete the reCAPTCHA.");
    //         setIsLoading(false);
    //         return;
    //     }

    //     try {
    //         const partnerCompanyId = localStorage.getItem('companyId') || 1;

    //         const formData = new FormData();
    //         formData.append('email', email);
    //         formData.append('user_role_id', 2);
    //         formData.append('first_name', firstName);
    //         formData.append('last_name', lastName);
    //         formData.append('phone', phone);
    //         formData.append('username', username);
    //         formData.append('password', password);
    //         formData.append('c_password', passwordConfirmation);
    //         formData.append('partner_company_id', partnerCompanyId);
    //         formData.append('trailer_type', trailerType);
    //         formData.append('signature', signatureFile);
    //         formData.append('g-recaptcha-response', recaptchaValue); // Incluindo o valor do reCAPTCHA no formulário

    //         await axios.post(`${process.env.REACT_APP_API_PREFIX}/user-driver`, formData, {
    //             headers: {
    //                 "Content-Type": "multipart/form-data"
    //             }
    //         });

    //         localStorage.setItem('registrationSuccess', 'true');

    //         Swal.fire({
    //             title: 'Success!',
    //             text: 'Registration successful! Check your email to confirm.',
    //             icon: 'success',
    //             confirmButtonText: 'Ok'
    //         }).then(() => {
    //             window.location.reload();
    //         });

    //         localStorage.removeItem('companyId');
    //     } catch (err) {
    //         setError("Registration not completed! An error occurred.");
    //         if (err.response && err.response.data) {
    //             Swal.fire({
    //                 title: 'Error!',
    //                 text: `Registration not completed! ${err.response.data.message}`,
    //                 icon: 'error',
    //                 confirmButtonText: 'OK'
    //             });
    //         } else {
    //             Swal.fire({
    //                 title: 'Error!',
    //                 text: 'Registration not completed! An unknown error occurred.',
    //                 icon: 'error',
    //                 confirmButtonText: 'OK'
    //             });
    //         }
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const errors = validateFields();
        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            setIsLoading(false);
            return;
        }

        if (!recaptchaValue) {
            setError("Please complete the reCAPTCHA.");
            setIsLoading(false);
            return;
        }

        try {
            const partnerCompanyId = localStorage.getItem('companyId') || 1;

            const formData = new FormData();
            formData.append('email', email);
            formData.append('user_role_id', 2);
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('phone', phone);
            formData.append('username', username);
            formData.append('password', password);
            formData.append('c_password', passwordConfirmation);
            formData.append('partner_company_id', partnerCompanyId);
            formData.append('trailer_type', trailerType);
            formData.append('signature', signatureFile);
            formData.append('g-recaptcha-response', recaptchaValue); // Incluindo o valor do reCAPTCHA no formulário

            console.log('Form Data:', {
                email,
                user_role_id: 2,
                first_name: firstName,
                last_name: lastName,
                phone,
                username,
                password,
                c_password: passwordConfirmation,
                partner_company_id: partnerCompanyId,
                trailer_type: trailerType,
                signature: signatureFile,
                'g-recaptcha-response': recaptchaValue,
            });

            // await axios.post(`${process.env.REACT_APP_API_PREFIX}/user-driver`, formData, {
            //     headers: {
                    
            //         "Content-Type": "multipart/form-data"
            //     }
            // });

            const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/user-driver`, formData);


            console.log("Response from server:", response.data);

            localStorage.setItem('registrationSuccess', 'true');

            Swal.fire({
                title: 'Success!',
                text: 'Registration successful! Check your email to confirm.',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                window.location.reload();
            });

            localStorage.removeItem('companyId');
        } catch (err) {
            setError("Registration not completed! An error occurred.");
            if (err.response && err.response.data) {
                Swal.fire({
                    title: 'Error!',
                    text: `Registration not completed! ${err.response.data.message}`,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Registration not completed! An unknown error occurred.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } finally {
            setIsLoading(false);
        }
    };



    const handleSignature = () => {
        const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        const signatureFile = dataURLtoFile(signatureData, 'signature.png');

        if (signatureFile.type.match(/image\/(jpeg|png|jpg|gif|svg)/) && signatureFile.size <= 2048 * 1024) {
            setSignatureFile(signatureFile);
            setFieldErrors(prevErrors => ({ ...prevErrors, signature: '' }));
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Signature must be an image file (jpeg, png, jpg, gif, svg) and not larger than 2MB.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const onChange = (value) => {
        console.log("Captcha value:", value);
        setRecaptchaValue(value); // Capturando o valor do reCAPTCHA
    };

    return (
        <Modal isOpen={true} onRequestClose={onBack} contentLabel="Driver Registration" className="flex items-center justify-center min-h-screen">
            <div className="bg-white rounded-lg shadow-lg p-6 mx-4 md:max-w-4xl w-full overflow-auto custom-scrollbar" style={{ maxHeight: '94vh', maxWidth: '41rem' }}>
                <a href="#" className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-12 h-12" src="./cajuu.png" alt="logo" />
                </a>
                <h2 className="text-2xl font-bold text-center mb-6">Driver Registration</h2>

                <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                    {message && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">{message}</div>}
                    {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}

                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`bg-gray-50 border ${fieldErrors.email ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                            required
                        />
                        {fieldErrors.email && <span className="text-red-500 text-sm">{fieldErrors.email}</span>}
                    </div>
                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.firstName ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                required
                            />
                            {fieldErrors.firstName && <span className="text-red-500 text-sm">{fieldErrors.firstName}</span>}
                        </div>
                        <div className="flex-1">
                            <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.lastName ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                required
                            />
                            {fieldErrors.lastName && <span className="text-red-500 text-sm">{fieldErrors.lastName}</span>}
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
                            <input
                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.phone ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                required
                            />
                            {fieldErrors.phone && <span className="text-red-500 text-sm">{fieldErrors.phone}</span>}
                        </div>
                        <div className="flex-1">
                            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.username ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                required
                            />
                            {fieldErrors.username && <span className="text-red-500 text-sm">{fieldErrors.username}</span>}
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.password ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                required
                            />
                            {fieldErrors.password && <span className="text-red-500 text-sm">{fieldErrors.password}</span>}
                        </div>
                        <div className="flex-1">
                            <label htmlFor="c_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                            <input
                                type="password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                className={`bg-gray-50 border ${fieldErrors.passwordConfirmation ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                required
                            />
                            {fieldErrors.passwordConfirmation && <span className="text-red-500 text-sm">{fieldErrors.passwordConfirmation}</span>}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="trailerType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Trailer Type</label>
                        <div className="flex items-center space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    name="trailerType"
                                    value="open"
                                    checked={trailerType === 'open'}
                                    onChange={(e) => setTrailerType(e.target.value)}
                                />
                                <span className="ml-2">Open</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    name="trailerType"
                                    value="enclosed"
                                    checked={trailerType === 'enclosed'}
                                    onChange={(e) => setTrailerType(e.target.value)}
                                />
                                <span className="ml-2">Enclosed</span>
                            </label>
                        </div>
                        {fieldErrors.trailerType && <span className="text-red-500 text-sm">{fieldErrors.trailerType}</span>}
                    </div>
                    <div className="signature-section mt-4">
                        <label htmlFor="signature" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Signature</label>
                        <div className="border-2 border-gray-300 rounded-lg p-4 bg-white dark:bg-gray-800">
                            <SignatureCanvas
                                penColor='black'
                                canvasProps={{ className: 'w-full h-48' }}
                                ref={sigCanvas}
                                onEnd={handleSignature}
                            />
                        </div>
                        <div className="flex justify-end mt-2">
                            <button onClick={() => sigCanvas.current.clear()} type="button" className="text-red-500 hover:text-red-700">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        {fieldErrors.signature && <span className="text-red-500 text-sm">{fieldErrors.signature}</span>}
                    </div>

                    <ReCAPTCHA
                        sitekey="6Le8GfwpAAAAAD6ud1WiozXOqjy2cakJcezWIEN6"
                        onChange={onChange}
                        className="flex justify-center my-4"
                    />

                    <div className="flex justify-evenly mt-6">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2"
                        >
                            {isLoading ? 'Registering...' : 'Register'}
                        </button>
                        <button
                            type="button"
                            onClick={() => history.push('/Login')}
                            className="w-full text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ml-2"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default DriverRegisterForm;
