import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Card } from '@material-tailwind/react';
import Spinner from '../components/MainSpinner'; // Ensure the spinner component is correctly imported
import { RiSendPlaneFill } from "react-icons/ri";

const initialFormValues = {
    TransportFrom: "",
    TransportFromZipcode: "",
    TransportFromCity: "",
    TransportFromState: "",
    TransportTo: "",
    TransportToZipcode: "",
    TransportToCity: "",
    TransportToState: "",
    TransportType: "",
    VehicleYear: "",
    VehicleMake: "",
    VehicleModel: "",
    Operable: "",
    FirstAvailableDate: "",
    Email: "",
    Name: "",
    Phone: "",
};

const initialCars = [];

function PageQuotes() {
    const [cars, setCars] = useState(initialCars);
    const [formValues, setFormValues] = useState(initialFormValues);
    const [loading, setLoading] = useState(true); // Set initial loading state to true
    const [isOpen, setIsOpen] = useState({
        card1: false,
        card2: false,
        card3: false
    });

    useEffect(() => {
        // Simulate initial loading time
        setTimeout(() => setLoading(false), 1000);
    }, []);

    const toggleCard = (cardKey) => {
        setIsOpen(prev => ({
            ...prev,
            [cardKey]: !prev[cardKey]
        }));
    };

    const handleCarChange = (carId, event) => {
        const { name, value } = event.target;
        const fieldName = name.split('-')[0];

        const updatedCars = cars.map(car => {
            if (car.id === carId) {
                return { ...car, [fieldName]: value };
            }
            return car;
        });

        setCars(updatedCars);
    };

    const addCar = () => {
        if (cars.length >= 8) {
            Swal.fire('Maximum number of cars reached', '', 'warning');
        } else {
            setCars([...cars, {
                id: Math.random().toString(),
                year: '',
                maker: '',
                model: '',
                isOperable: ''
            }]);
        }
    };

    const deleteCar = (carId) => {
        const updatedCars = cars.filter(car => car.id !== carId);
        setCars(updatedCars);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading

        const quoteVehicles = [{
            year: formValues.VehicleYear,
            make: formValues.VehicleMake,
            model: formValues.VehicleModel,
            operable: formValues.Operable === "OperableYes" ? 1 : 0
        }, ...cars.map(car => ({
            year: car.year,
            make: car.maker,
            model: car.model,
            operable: car.isOperable === "OperableYes" ? 1 : 0
        }))];

        const formData = {
            TransportFrom: formValues.TransportFrom,
            TransportFromZipcode: formValues.TransportFromZipcode,
            TransportFromCity: formValues.TransportFromCity,
            TransportFromState: formValues.TransportFromState,
            TransportTo: formValues.TransportTo,
            TransportToZipcode: formValues.TransportToZipcode,
            TransportToCity: formValues.TransportToCity,
            TransportToState: formValues.TransportToState,
            TransportType: formValues.TransportType,
            FirstAvailableDate: formValues.FirstAvailableDate,
            Email: formValues.Email,
            Name: formValues.Name,
            Phone: formValues.Phone,
            quote_vehicles: quoteVehicles
        };

        console.log("Dados a serem enviados:", formData);

        try {
            const userToken = localStorage.getItem('userToken');
            const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/quote/internal/`,
                formData,
                {
                    headers: {
                        "Screen-Name": "/NewQuotes",
                        "Authorization": `Bearer ${userToken}`
                    }
                }
            );

            Swal.fire({
                title: 'Success!',
                text: 'Form sent successfully!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                setFormValues(initialFormValues);
                setCars(initialCars);
            });
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'There was an error submitting the form. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        } finally {
            setLoading(false); // End loading
        }
    };

    function handleTransportFromChange(event) {
        const { value } = event.target;

        setFormValues(prevState => ({
            ...prevState,
            TransportFrom: value
        }));

        // Checa se o valor tem 5 dígitos e corresponde a um formato de ZIP code dos EUA
        if (/^\d{5}$/.test(value)) {
            // Chama a função para lidar com a busca de ZIP code
            handleTransportFromZipcode(value);
        } else if (value.length > 5) {
            // Separa a cidade e o estado com base na vírgula e no espaço
            const [city, state = ''] = value.split(',').map(s => s.trim());
            setFormValues(prevState => ({
                ...prevState,
                TransportFromCity: city,
                TransportFromState: state
            }));
        }
    }

    async function handleTransportFromZipcode(zipcode) {
        try {
            // Aqui você faz a chamada à API de geolocalização
            const response = await axios.get(`https://api.zippopotam.us/us/${zipcode}`);
            const placeInfo = response.data.places[0];

            setFormValues(prevState => ({
                ...prevState,
                TransportFromCity: placeInfo['place name'],
                TransportFromState: placeInfo['state abbreviation'],
                TransportFromZipcode: zipcode,
                TransportFrom: placeInfo['place name'] + ', ' + placeInfo['state abbreviation'],
            }));
        } catch (error) {
            setFormValues(prevState => ({
                ...prevState,
                TransportFromZipcode: zipcode,
            }));
            console.error('Erro ao buscar dados de geolocalização:', error);
        }
    }

    function handleTransportToChange(event) {
        const { value } = event.target;

        setFormValues(prevState => ({
            ...prevState,
            TransportTo: value
        }));

        // Checa se o valor tem 5 dígitos e corresponde a um formato de ZIP code dos EUA
        if (/^\d{5}$/.test(value)) {
            // Chama a função para lidar com a busca de ZIP code
            handleTransportToZipcode(value);
        } else if (value.length > 5) {
            // Separa a cidade e o estado com base na vírgula e no espaço
            const [city, state = ''] = value.split(',').map(s => s.trim());
            setFormValues(prevState => ({
                ...prevState,
                TransportToCity: city,
                TransportToState: state
            }));
        }
    }

    async function handleTransportToZipcode(zipcode) {
        try {
            // Aqui você faz a chamada à API de geolocalização
            const response = await axios.get(`https://api.zippopotam.us/us/${zipcode}`);
            const placeInfo = response.data.places[0];

            setFormValues(prevState => ({
                ...prevState,
                TransportToCity: placeInfo['place name'],
                TransportToState: placeInfo['state abbreviation'],
                TransportToZipcode: zipcode,
                TransportTo: placeInfo['place name'] + ', ' + placeInfo['state abbreviation'],
            }));
        } catch (error) {
            setFormValues(prevState => ({
                ...prevState,
                TransportToZipcode: zipcode,
            }));
            console.error('Erro ao buscar dados de geolocalização:', error);
        }
    }

    return (
        <div className="px-4">
            {loading ? (
                <Spinner />
            ) : (
                <div>
                    <Card className="mt-4 mx-auto bg-white dark:bg-gray-800 shadow-xl rounded-lg overflow-hidden transition-all duration-300 ease-in-out">
                        <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card1')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12.0001 12.9999C13.6569 12.9999 15.0001 11.6568 15.0001 9.99994C15.0001 8.34309 13.6569 6.99994 12.0001 6.99994C10.3432 6.99994 9.00005 8.34309 9.00005 9.99994C9.00005 11.6568 10.3432 12.9999 12.0001 12.9999Z" stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.8001 13.9379H17.7891C18.5109 12.8799 18.9286 11.6441 18.9967 10.3651C19.0648 9.08607 18.7807 7.81289 18.1752 6.68419C17.5698 5.55548 16.6663 4.61452 15.5632 3.96376C14.46 3.31299 13.1994 2.97739 11.9187 2.99347C10.638 3.00956 9.3862 3.37673 8.29971 4.05499C7.21322 4.73326 6.33365 5.69662 5.75678 6.84018C5.17991 7.98373 4.92785 9.26364 5.02805 10.5405C5.12825 11.8174 5.57687 13.0424 6.32505 14.0819H6.30905L6.45005 14.2529C6.55005 14.3799 6.65005 14.5039 6.75005 14.6239L12.0001 20.9999L17.1301 14.7519C17.3231 14.5433 17.5034 14.3232 17.6701 14.0929L17.8001 13.9379Z" stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Destination</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                                <path d="M18 15l-6-6-6 6" />
                            </svg>
                        </div>
                        {isOpen.card1 && (
                            <div className="p-6 space-y-4">
                                <div>
                                    <label htmlFor="TransportFrom" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">From:</label>
                                    <input
                                        id="TransportFrom"
                                        type="text"
                                        name="TransportFrom"
                                        onChange={handleInputChange}
                                        value={formValues.TransportFrom}
                                        className="form-control block w-full px-4 py-2 text-base font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        placeholder="Zipcode or City, State"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="TransportTo" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">To:</label>
                                    <input
                                        id="TransportTo"
                                        type="text"
                                        name="TransportTo"
                                        onChange={handleInputChange}
                                        value={formValues.TransportTo}
                                        className="form-control block w-full px-4 py-2 text-base font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        placeholder="Zipcode or City, State"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="TransportType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Transport Type:
                                    </label>
                                    <select
                                        id="TransportType"
                                        name="TransportType"
                                        onChange={handleInputChange}
                                        value={formValues.TransportType}
                                        className="form-control block w-full px-4 py-2 text-base font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                    >
                                        <option value="">Select Transport Type</option>
                                        <option value="TransportTypeOpen">Open</option>
                                        <option value="TransportTypeEnclosed">Enclosed</option>
                                    </select>
                                </div>
                            </div>
                        )}
                    </Card>
                    <Card className="mt-4 mx-auto bg-white dark:bg-gray-800 shadow-xl rounded-lg overflow-hidden transition-all duration-300 ease-in-out">
                        <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card2')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                <path d="M15.5 10.25C14.2574 10.25 13.25 11.2574 13.25 12.5C13.25 13.7426 14.2574 14.75 15.5 14.75C16.7426 14.75 17.75 13.7426 17.75 12.5C17.75 11.2574 16.7426 10.25 15.5 10.25ZM15.5 10.25C15.185 10.25 14.8736 10.3167 14.5864 10.446C14.2992 10.5753 14.0428 10.7642 13.834 11H12M15.5 10.25C15.815 10.25 16.1264 10.3167 16.4136 10.446C16.7008 10.5753 16.9572 10.7642 17.166 11H19V7M12 11V3H17L19 7M12 11H6.166C5.95722 10.7642 5.70076 10.5753 5.41356 10.446C5.12636 10.3167 4.81497 10.25 4.5 10.25M12 11V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V11H2.834C3.04278 10.7642 3.29924 10.5753 3.58644 10.446C3.87364 10.3167 4.18503 10.25 4.5 10.25M19 7H13M4.5 10.25C3.25736 10.25 2.25 11.2574 2.25 12.5C2.25 13.7426 3.25736 14.75 4.5 14.75C5.74264 14.75 6.75 13.7426 6.75 12.5C6.75 11.2574 5.74264 10.25 4.5 10.25Z" stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Vehicle Information</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                                <path d="M18 15l-6-6-6 6" />
                            </svg>
                        </div>
                        {isOpen.card2 && (
                            <div className="p-6 space-y-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Vehicle Year:</label>
                                        <input
                                            type="number"
                                            name="VehicleYear"
                                            onChange={handleInputChange}
                                            value={formValues.VehicleYear}
                                            className="form-control block w-full px-3 py-2 text-base font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            placeholder="2024"
                                        />
                                    </div>
                                    <div>
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Vehicle Maker:</label>
                                        <input
                                            type="text"
                                            name="VehicleMake"
                                            onChange={handleInputChange}
                                            value={formValues.VehicleMake}
                                            className="form-control block w-full px-3 py-2 text-base font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            placeholder="Honda"
                                        />
                                    </div>
                                    <div>
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Vehicle Model:</label>
                                        <input
                                            type="text"
                                            name="VehicleModel"
                                            onChange={handleInputChange}
                                            value={formValues.VehicleModel}
                                            className="form-control block w-full px-3 py-2 text-base font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            placeholder="Civic"
                                        />
                                    </div>
                                    <div>
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Is It Operable:</label>
                                        <select
                                            name="Operable"
                                            onChange={handleInputChange}
                                            value={formValues.Operable}
                                            className="form-control block w-full px-3 py-2 text-base font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        >
                                            <option value="">Select Is Operable</option>
                                            <option value="OperableYes">Yes</option>
                                            <option value="OperableNo">No</option>
                                        </select>
                                    </div>
                                </div>
                                {cars.map((car, index) => (
                                    <div key={index} className="p-6 mt-4">
                                        <Card className="p-2 mt-4">
                                            <div className="flex justify-between items-center mb-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                                    <path d="M15.5 10.25C14.2574 10.25 13.25 11.2574 13.25 12.5C13.25 13.7426 14.2574 14.75 15.5 14.75C16.7426 14.75 17.75 13.7426 17.75 12.5C17.75 11.2574 16.7426 10.25 15.5 10.25ZM15.5 10.25C15.185 10.25 14.8736 10.3167 14.5864 10.446C14.2992 10.5753 14.0428 10.7642 13.834 11H12M15.5 10.25C15.815 10.25 16.1264 10.3167 16.4136 10.446C16.7008 10.5753 16.9572 10.7642 17.166 11H19V7M12 11V3H17L19 7M12 11H6.166C5.95722 10.7642 5.70076 10.5753 5.41356 10.446C5.12636 10.3167 4.81497 10.25 4.5 10.25M12 11V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V11H2.834C3.04278 10.7642 3.29924 10.5753 3.58644 10.446C3.87364 10.3167 4.18503 10.25 4.5 10.25M19 7H13M4.5 10.25C3.25736 10.25 2.25 11.2574 2.25 12.5C2.25 13.7426 3.25736 14.75 4.5 14.75C5.74264 14.75 6.75 13.7426 6.75 12.5C6.75 11.2574 5.74264 10.25 4.5 10.25Z" stroke="#2F2F38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <h3 className="text-lg font-medium">Vehicle Information {index + 1}</h3>
                                                <button onClick={() => deleteCar(car.id)} className="text-red-500 hover:text-red-700">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label className="block text-sm font-medium">Year:</label>
                                                    <input
                                                        type="number"
                                                        name="year"
                                                        value={car.year}
                                                        onChange={(e) => handleCarChange(car.id, e)}
                                                        className="form-control block w-full px-3 py-2 text-sm font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600"
                                                        placeholder="Year"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium">Maker:</label>
                                                    <input
                                                        type="text"
                                                        name="maker"
                                                        value={car.maker}
                                                        onChange={(e) => handleCarChange(car.id, e)}
                                                        className="form-control block w-full px-3 py-2 text-sm font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600"
                                                        placeholder="Maker"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium">Model:</label>
                                                    <input
                                                        type="text"
                                                        name="model"
                                                        value={car.model}
                                                        onChange={(e) => handleCarChange(car.id, e)}
                                                        className="form-control block w-full px-3 py-2 text-sm font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600"
                                                        placeholder="Model"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium">Is Operable:</label>
                                                    <select
                                                        name={`isOperable-${car.id}`}
                                                        value={car.isOperable}
                                                        onChange={(e) => handleCarChange(car.id, e)}
                                                        className="form-control block w-full px-3 py-2 text-sm font-normal bg-white border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="OperableYes">Yes</option>
                                                        <option value="OperableNo">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => deleteCar(car.id)}
                                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-8 justify-end"
                                            >
                                                Delete
                                            </button>
                                        </Card>
                                    </div>
                                ))}
                                <div className="flex justify-center mt-6">
                                    <button onClick={(e) => { e.preventDefault(); addCar(); }} className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded">
                                        Add New Car
                                    </button>
                                </div>
                            </div>
                        )}
                    </Card>
                    <Card className="mt-4 mx-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
                        <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card3')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 8V12L15.276 15.276M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="orange" stroke-width="2" stroke-linejoin="round" />
                            </svg>
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Date</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                                <path d="M18 15l-6-6-6 6" />
                            </svg>
                        </div>
                        {isOpen.card3 && (
                            <form className="p-6">
                                <div className="flex flex-wrap gap-4 justify-between">
                                    <div className="flex flex-col md:flex-row gap-4 w-full">
                                        <div className="md:w-1/2">
                                            <label htmlFor="pickup_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pickup Date:</label>
                                            <input
                                                type="date"
                                                id="pickup_date"
                                                name="FirstAvailableDate"
                                                onChange={handleInputChange}
                                                value={formValues.FirstAvailableDate}
                                                className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            />
                                        </div>
                                        <div className="md:w-1/2">
                                            <label htmlFor="customer_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name:</label>
                                            <input
                                                type="text"
                                                id="customer_name"
                                                name="Name"
                                                onChange={handleInputChange}
                                                value={formValues.Name}
                                                className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                                placeholder="Enter Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-4 w-full">
                                        <div className="md:w-1/2">
                                            <label htmlFor="send_quote_response_to" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Send quote response to:</label>
                                            <input
                                                type="email"
                                                id="send_quote_response_to"
                                                name="Email"
                                                onChange={handleInputChange}
                                                value={formValues.Email}
                                                className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                                placeholder="Name@Cajuu.com"
                                            />
                                        </div>
                                        <div className="md:w-1/2">
                                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone:</label>
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="Phone"
                                                onChange={handleInputChange}
                                                value={formValues.Phone}
                                                className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                                placeholder="123-456-7890"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Card>

                    <div className="flex justify-center mt-4">
                        <button
                            onClick={handleSubmit}
                            className="flex items-center justify-center text-white font-bold py-2 px-4 rounded mx-2 mr-2 w-8/12 md:w-2/12"
                            style={{ backgroundColor: 'orange', marginBottom: '1rem' }}
                        >
                            <RiSendPlaneFill className="mr-2" /> Submit
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PageQuotes;