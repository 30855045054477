// hooks/useHomePage.js
import {jwtDecode} from 'jwt-decode';

const UseHomePage = () => {
  // Define um valor padrão para home_page caso não encontre no token ou não haja token
  const defaultHomePage = '/';
  
  try {
    const token = localStorage.getItem('userToken');
    if (!token) return defaultHomePage;

    const decoded = jwtDecode(token);
    return decoded.home_page || defaultHomePage;
  } catch (error) {
    console.error('Erro ao decodificar o token JWT:', error);
    return defaultHomePage;
  }
};

export default UseHomePage;
