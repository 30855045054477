import React from "react";

function Inspection() {
  // todo : button to add new inspection

  // check button click

  return;
  <div>{/* todo structure of isnpection */}</div>;
}

export default Inspection;
