import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Image from '@material-tailwind/react/Image';
import H5 from '@material-tailwind/react/Heading5';
import Icon from '@material-tailwind/react/Icon';
import LeadText from '@material-tailwind/react/LeadText';
import Button from '@material-tailwind/react/Button';
import ProfilePicture from 'assets/img/team-1-800x800.jpg';
import DefaultPicture from '../assets/img/img-profile-error.png'; // Importando a imagem padrão

export default function ProfileCard() {
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        userRole: '',
        profilePicturePath: ProfilePicture,
        partnerCompany: '',
        city: 'City',
        state: 'State',
        office: 'Office',
        company: 'Company',
        training: 'Training'
    });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getUserDetailsFromToken = async () => {
            const token = localStorage.getItem('userToken');
            if (token) {
                const decoded = jwtDecode(token);
                const initialUserData = {
                    username: `${decoded.first_name} ${decoded.last_name}`,
                    email: decoded.email,
                    userRole: decoded.user_role_name,
                    training: decoded.user_role_name,
                    partnerCompany: decoded.partner_company_name,
                    company: decoded.partner_company_name,
                    profilePicturePath: decoded.profile_picture_path ? `${process.env.REACT_APP_API_FILE_PREFIX}/${decoded.profile_picture_path}` : DefaultPicture // Use a imagem padrão
                };

                setUserData((prevUserData) => ({
                    ...prevUserData,
                    ...initialUserData
                }));

                const userId = decoded.user_id;

                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/users/${userId}`, {
                        headers: {
                            "Screen-Name": "/Profile",
                            "Authorization": `Bearer ${token}`
                        }
                    });

                    if (response.data && response.data.data) {
                        const user = response.data.data;
                        setUserData((prevUserData) => ({
                            ...prevUserData,
                            username: user.username,
                            email: user.email,
                            firstName: user.first_name,
                            lastName: user.last_name,
                            phone: user.phone,
                            profilePicturePath: user.profile_picture_path ? `${process.env.REACT_APP_API_FILE_PREFIX}/${user.profile_picture_path}` : DefaultPicture, // Use a imagem padrão
                            userRole: user.user_role_id,
                            partnerCompany: user.partner_company_id,
                            city: user.city || 'City',
                            state: user.state || 'State',
                            office: user.office || 'Office',
                            company: user.partner_company_name || decoded.partner_company_name,
                            training: user.user_role_name || decoded.user_role_name
                        }));
                    } else {
                        throw new Error('Dados do usuário não encontrados na resposta da API');
                    }
                } catch (error) {
                    console.error("Erro ao buscar dados do usuário", error);
                    setError("Erro ao buscar dados do usuário: " + error.message);
                    setUserData((prevUserData) => ({
                        ...prevUserData,
                        profilePicturePath: DefaultPicture // Defina a imagem padrão em caso de erro
                    }));
                }
            } else {
                setError("Token do usuário não encontrado");
            }
        };

        getUserDetailsFromToken();
    }, []);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('profile_picture', file);

        try {
            setIsLoading(true);
            const token = localStorage.getItem('userToken');
            const userId = jwtDecode(token).user_id;

            console.log("Iniciando upload da imagem...");

            // Fazer o upload do arquivo da imagem
            const uploadResponse = await axios.post(
                `${process.env.REACT_APP_API_PREFIX}/user-profile-picture`,
                formData,
                {
                    headers: {
                        "Screen-Name": "/Profile",
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "multipart/form-data"
                    }
                }
            );

            console.log("Resposta do upload da imagem:", uploadResponse);

            if (uploadResponse.data && uploadResponse.data.data) {
                const profilePicturePath = `${process.env.REACT_APP_API_FILE_PREFIX}/${uploadResponse.data.data.profile_picture_path}`;
                const updatedUserData = {
                    ...userData,
                    profilePicturePath
                };
                setUserData(updatedUserData);
                console.log("Imagem atualizada com sucesso:", updatedUserData);

                Swal.fire({
                    title: 'Success!',
                    text: 'Image uploaded successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            } else {
                throw new Error('Erro ao fazer upload da imagem');
            }
        } catch (error) {
            console.error("Erro ao fazer upload da imagem", error);
            setError("Erro ao fazer upload da imagem: " + error.message);
            Swal.fire({
                title: 'Error!',
                text: 'Failed to upload image. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageDelete = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('userToken');
            const userId = jwtDecode(token).user_id;

            console.log("Iniciando exclusão da imagem...");

            const response = await axios.delete(
                `${process.env.REACT_APP_API_PREFIX}/user-profile-picture`,
                {
                    headers: {
                        "Screen-Name": "/Profile",
                        "Authorization": `Bearer ${token}`
                    }
                }
            );

            console.log("Resposta da exclusão da imagem:", response);

            if (response.status === 200) {
                const updatedUserData = {
                    ...userData,
                    profilePicturePath: DefaultPicture // Atualize para usar a imagem padrão após exclusão
                };
                setUserData(updatedUserData);
                console.log("Imagem deletada com sucesso:", updatedUserData);

                Swal.fire({
                    title: 'Success!',
                    text: 'Image deleted successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            } else {
                throw new Error('Erro ao deletar a imagem');
            }
        } catch (error) {
            console.error("Erro ao deletar a imagem", error);
            setError("Erro ao deletar a imagem: " + error.message);
            Swal.fire({
                title: 'Error!',
                text: 'Failed to delete image. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Card className="-mt-12">
            <div className="flex flex-wrap justify-center">
                <div className="w-48 px-4 -mt-16 relative">
                    <label htmlFor="profilePictureUpload" className="relative cursor-pointer">
                        <div className="transform -translate-y-12 hover:shadow-2xl transition-shadow duration-300 rounded-full">
                            <img
                                src={userData.profilePicturePath || DefaultPicture} // Use a imagem padrão se nenhuma imagem estiver disponível
                                alt="Profile"
                                className="block object-cover w-48 h-48 rounded-full border-4 border-gray-300"
                                onError={(e) => { e.target.onerror = null; e.target.src = DefaultPicture; }} // Configura a imagem padrão em caso de erro
                            />
                        </div>
                        <input
                            type="file"
                            id="profilePictureUpload"
                            accept="image/*"
                            className="hidden"
                            onChange={handleImageUpload}
                        />
                        <button
                            className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 focus:outline-none shadow-md hover:bg-red-600"
                            onClick={handleImageDelete}
                            style={{ transform: 'translate(50%, -50%)' }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </label>
                </div>
            </div>

            <div className="text-center">
                <H5 color="gray">{userData.username}</H5>
                <div className="mb-2 text-gray-700 mt-10 flex items-center justify-center gap-2">
                    <Icon name="work" size="xl" />
                    {userData.company}
                </div>
                <div className="mb-2 text-gray-700 flex items-center justify-center gap-2">
                    <Icon name="account_balance" size="xl" />
                    {userData.training}
                </div>
            </div>
            <CardBody>
                <div className="border-t border-lightBlue-200 text-center px-2 ">
                    <LeadText color="blueGray">
                        Welcome to Cajuu.app!
                        Here, we value efficiency and quality in vehicle transportation.
                        Our platform is designed to offer an intuitive user experience and to facilitate the management
                        of all your transportation needs.
                        We appreciate your choice of our solution and are here to help you achieve your goals with excellence.
                    </LeadText>
                </div>
            </CardBody>
        </Card>
    );
}