import axios from "axios";
import React, { useEffect, useState } from "react";
//import { toast } from "react-toastify";
import { Card, Button } from "@material-tailwind/react";
import '../components/css/User.css';



function UserPage() {
  // variable to store the data from the API
  const [userRoles, setUserRoles] = useState([]);

  // function to get the data from the API
  const getUserRoles = async () => {
    const response = await axios.get("https://new.cajuu.app/public/api/userrole");

    // set the data from the API to the variable
    setUserRoles(response.data.data);
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  // submit form
  const submitForm = async (e) => {
    e.preventDefault();

    // get the values from the form
    const formData = new FormData(e.target);

    // validate all inputs
    for (let [key, value] of formData.entries()) {
      if (value === "") {
        alert(`${key} is empty`);
        return;
      }
    }

    // get all the values from the form by destructuring
    const {
      firstname,
      lastname,
      phone,
      userrole,
      email,
      username,
      password,
      confirmpassword,
    } = Object.fromEntries(formData.entries());

    // check if password and confirm password are the same
    if (password !== confirmpassword) {
      alert("Passwords do not match");
      return;
    }

    let userPayload = {
      first_name: firstname,
      last_name: lastname,
      phone: phone,
      user_role_id: userrole,
      email: email,
      username: username,
      password: password,
      profile_picture_path: "default.png",
    };

    // send the data to the API
    // const response = await axios.post('http://localhost:8000/api/users', userPayload);
    // console.log(response.data);

    // try catch
    try {
      const response = await axios.post(
        "https://new.cajuu.app/public/api/users",
        userPayload
      );
      console.log(response.data);
      //toast.success("User added successfully");
    } catch (error) {
      console.log(error.response);
      //toast.error("Error adding user");
    }

    // reset form
    e.target.reset();
  };

  return (
    <div className="px-4">

      <Card className="p-2 mt-4">
        <form className="flex flex-col" onSubmit={submitForm}>
          <div className="flex w-full flex-col p-10 border-1">

            <div className="mt-5">

              <div className="flex flex-wrap -mx-2">
                {/* CAMPO First Name */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="firstname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name:</label>
                  <div className="flex">
                    <input
                      style={{ width: '18rem' }}
                      type="text"
                      name="firstname"
                      id="first-name"
                      autoComplete="given-name"
                      className="InputMobile rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter First Name"
                    />
                  </div>
                </div>

                {/* CAMPO LAST NAME */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="contactname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name:</label>
                  <div className="flex">
                    <input
                      style={{ width: '18rem' }}
                      name="lastname"
                      type="text"
                      id="last-name"
                      autoComplete="family-name"
                      className="InputMobile rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>

                {/* CAMPO PHONE */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone:</label>
                  <div className="flex">
                    <input
                      style={{ width: '18rem' }}
                      type="tel"
                      name="phone"
                      id="phone"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="123-456-7890"
                    />
                  </div>
                </div>

                {/* CAMPO USER-ROLE */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    User Role:
                  </label>
                  <div className="flex">
                    <select
                      name="userrole"
                      // className="InputMobile block px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Select User Role</option>
                      {userRoles.map((userRole) => (
                        <option key={userRole.id} value={userRole.id}>
                          {userRole.user_role_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* CAMPO E-MAIL */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    E-mail:
                  </label>
                  <div className="flex">
                    <input
                      name="email"
                      placeholder="name@cajuu.com"
                      type="email"
                      // className="InputMobile block px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>

                {/* CAMPO USERNAME */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Username:
                  </label>
                  <div className="flex">
                    <input
                      name="username"
                      type="text"
                      placeholder="Enter Username"
                      // className="InputMobile block px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>

                {/* CAMPO PASSWORD */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Password:
                  </label>
                  <div className="flex">
                    <input
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      // className="InputMobile block px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>

                {/* CAMPO PASSWORD */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label
                    htmlFor="confirmpassword"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Confirm Password:
                  </label>
                  <div className="flex">
                    <input
                      name="confirmpassword"
                      type="password"
                      placeholder="Enter Confirm Password"
                      // className="InputMobile block px-3 py-2 rounded-md border-2 border-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-8">
              <Button type="submit" className="mx-2 mr-2" style={{ backgroundColor: 'orange', marginTop: '0rem' }}>
                Add User
              </Button>
            </div>
          </div>
        </form >
      </Card>

    </div >
  );
}

export default UserPage;
