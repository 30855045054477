import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import './css/VehicleInspection.css';

const VehicleInspection = forwardRef(({ diagramaURL, tipoInspecao = 'pickup', readOnly = false, deliveryDiagramURL = null }, ref) => {
  const canvasRef = useRef(null);
  const [avariaSelecionada, setAvariaSelecionada] = useState(null);
  const [imagensAvarias, setImagensAvarias] = useState({});
  const [marcacoes, setMarcacoes] = useState([]);

  const tiposAvaria = {
    'Undo': '/diagram_buttons/undo.webp',
    'Bent': '/diagram_buttons/' + tipoInspecao + '/b.webp',
    'Buffer Burned': '/diagram_buttons/' + tipoInspecao + '/bb.webp',
    'Broken': '/diagram_buttons/' + tipoInspecao + '/br.webp',
    'Cut': '/diagram_buttons/' + tipoInspecao + '/c.webp',
    'Cracked': '/diagram_buttons/' + tipoInspecao + '/cr.webp',
    'Dented': '/diagram_buttons/' + tipoInspecao + '/d.webp',
    'Loose': '/diagram_buttons/' + tipoInspecao + '/l.webp',
    'Missing': '/diagram_buttons/' + tipoInspecao + '/m.webp',
    'Paint Chip': '/diagram_buttons/' + tipoInspecao + '/pc.webp',
    'Rubbed': '/diagram_buttons/' + tipoInspecao + '/r.webp',
    'Scratched': '/diagram_buttons/' + tipoInspecao + '/s.webp',
    'Stained': '/diagram_buttons/' + tipoInspecao + '/st.webp',
  };

  useEffect(() => {
    const carregarImagens = async () => {
      let imagensCarregadas = {};
      for (const [tipo, caminho] of Object.entries(tiposAvaria)) {
        const imagem = new Image();
        imagem.src = caminho;
        await new Promise((resolve) => {
          imagem.onload = resolve;
        });
        imagensCarregadas[tipo] = imagem;
      }
      setImagensAvarias(imagensCarregadas);
    };

    carregarImagens();
  }, [tiposAvaria]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const imagemDiagrama = new Image();
    imagemDiagrama.src = tipoInspecao === 'dropoff' && deliveryDiagramURL ? deliveryDiagramURL : diagramaURL;

    imagemDiagrama.onload = () => {
      canvas.width = imagemDiagrama.naturalWidth;
      canvas.height = imagemDiagrama.naturalHeight;
      context.drawImage(imagemDiagrama, 0, 0, canvas.width, canvas.height);
      redesenharMarcações(1);
    };

  }, [marcacoes, diagramaURL, deliveryDiagramURL, tipoInspecao]);

  const redesenharMarcações = (escala) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    marcacoes.forEach(marcacao => {
      const imagemAvaria = imagensAvarias[marcacao.tipo];
      const posX = marcacao.x;
      const posY = marcacao.y;
      context.drawImage(imagemAvaria, posX, posY, 80 * escala, 80 * escala);
    });
  };

  const adicionarAvaria = (e) => {
    if (readOnly || tipoInspecao === 'dropoff' || !avariaSelecionada) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    const xClique = (e.clientX - rect.left) * (canvas.width / rect.width);
    const yClique = (e.clientY - rect.top) * (canvas.height / rect.height);

    const ajuste = 40;

    const xAjustado = xClique - ajuste;
    const yAjustado = yClique - ajuste;

    setMarcacoes([...marcacoes, { tipo: avariaSelecionada, x: xAjustado, y: yAjustado }]);
  };

  const gerarImagemFinal = () => {
    const canvas = canvasRef.current;
    const imagemURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'inspecao-veiculo.png';
    link.href = imagemURL;
    link.click();
  };

  const desfazerUltimaAvaria = () => {
    if (marcacoes.length === 0) return;
    const novasMarcacoes = marcacoes.slice(0, -1);
    setMarcacoes(novasMarcacoes);
  };

  useImperativeHandle(ref, () => ({
    getDiagramImage: () => {
      return canvasRef.current.toDataURL('image/png');
    }
  }));

  return (
    <div>
      <canvas ref={canvasRef} onClick={adicionarAvaria} />
      {!readOnly && tipoInspecao !== 'dropoff' && (
        <div className="painel-buttons">
          <div className="buttons-container">
            <button
              onClick={desfazerUltimaAvaria}
              className={`undo-button`}
            >
              <img src={tiposAvaria['Undo']} alt="Undo" />
              <label>Undo</label>
            </button>
            <div className="shadow-left"></div>
            <div className="scrollable-buttons">
              {Object.entries(tiposAvaria).filter(([tipo]) => tipo !== 'Undo').map(([tipo, caminhoImagem]) => (
                <button
                  key={tipo}
                  onClick={() => setAvariaSelecionada(tipo)}
                  className={`avaria-button`}
                >
                  <img src={caminhoImagem} alt={tipo}
                    className={`${avariaSelecionada === tipo ? 'selected' : ''}`}
                  />
                  <label>{tipo}</label>
                </button>
              ))}
            </div>
            <div className="shadow-right"></div>
          </div>
        </div>
      )}
      <br />
    </div>
  );

});

export default VehicleInspection;