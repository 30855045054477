import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import ScrollToTopButton from '../components/ScrollToTopButton';
import QuoteCard from '../components/QuoteCard';
import '../components/css/Load.css';
import Spinner from '../components/MainSpinner';

function NewQuotes() {
    const [quotes, setQuotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        id: '',
        model: '',
        maker: '',
        year: '',
        from: '',
        to: ''
    });
    const [filteredQuotes, setFilteredQuotes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const quotesPerPage = 12;

    const getQuotes = useCallback(async () => {
        try {
            setLoading(true);
            const userToken = localStorage.getItem('userToken');
            const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/quote`, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });
            // console.log("Dados recebidos: ", response.data.data); // Debug dos dados recebidos
            const sortedQuotes = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setQuotes(sortedQuotes);
        } catch (error) {
            console.error("Error while fetching data: ", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        getQuotes();
    }, [getQuotes]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    const applyFilters = () => {
        const filteredQuotes = quotes.filter(quote => {
            const idMatches = filters.id ? quote.id.toString() === filters.id.toString() : true;

            if (!quote.quote_vehicles || quote.quote_vehicles.length === 0) {
                return false; // Se não houver veículos, não inclui essa cotação nos resultados filtrados
            }

            const vehicleMatches = quote.quote_vehicles.some(vehicle => {
                const modelMatches = filters.model ? vehicle.model && vehicle.model.toLowerCase().includes(filters.model.toLowerCase()) : true;
                const makerMatches = filters.maker ? vehicle.make && vehicle.make.toLowerCase().includes(filters.maker.toLowerCase()) : true;
                const yearMatches = filters.year ? vehicle.year.toString() === filters.year.toString() : true;

                return modelMatches && makerMatches && yearMatches;
            });

            return idMatches && vehicleMatches;
        });

        setFilteredQuotes(filteredQuotes);
    };

    const indexOfLastQuote = currentPage * quotesPerPage;
    const indexOfFirstQuote = indexOfLastQuote - quotesPerPage;
    const currentQuotes = (filteredQuotes.length > 0 ? filteredQuotes : quotes).slice(indexOfFirstQuote, indexOfLastQuote);

    const nextPage = () => {
        if (currentPage < Math.ceil((filteredQuotes.length > 0 ? filteredQuotes : quotes).length / quotesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="App w-full min-h-screen bg-gray-100">
            <ScrollToTopButton />
            <div className="filter-navbar py-4 px-6 bg-white shadow-md">
                <div className="flex gap-4 mb-4">
                    <input
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                        placeholder="Search ID"
                    />
                    <input
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        name="model"
                        value={filters.model}
                        onChange={handleFilterChange}
                        placeholder="Search Model"
                    />
                    <input
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 filter-mobile"
                        name="maker"
                        value={filters.maker}
                        onChange={handleFilterChange}
                        placeholder="Search Maker"
                    />
                    <input
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 filter-mobile"
                        name="year"
                        value={filters.year}
                        onChange={handleFilterChange}
                        placeholder="Search Year"
                    />
                    <input
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 filter-mobile"
                        name="from"
                        value={filters.from}
                        onChange={handleFilterChange}
                        placeholder="From"
                        type="date"
                    />
                    <input
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 filter-mobile"
                        name="to"
                        value={filters.to}
                        onChange={handleFilterChange}
                        placeholder="To"
                        type="date"
                    />
                    <button
                        className="p-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none"
                        onClick={applyFilters}
                    >
                        Enter
                    </button>
                </div>
            </div>
            <div className="content px-6 py-4">
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                            {currentQuotes.map((quote) => (
                                <QuoteCard key={quote.id} quote={quote} className="quote-card" />
                            ))}
                        </div>
                        <div className="pagination flex justify-center mt-10">
                            <button
                                onClick={prevPage}
                                disabled={currentPage === 1}
                                className={`px-4 py-2 mx-2 border rounded-md text-white ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-orange-500 hover:bg-orange-600 focus:outline-none'}`}
                            >
                                Previous
                            </button>
                            <button
                                onClick={nextPage}
                                disabled={currentPage === Math.ceil((filteredQuotes.length > 0 ? filteredQuotes : quotes).length / quotesPerPage)}
                                className={`px-4 py-2 mx-2 border rounded-md text-white ${currentPage === Math.ceil((filteredQuotes.length > 0 ? filteredQuotes : quotes).length / quotesPerPage) ? 'bg-gray-300 cursor-not-allowed' : 'bg-orange-500 hover:bg-orange-600 focus:outline-none'}`}
                            >
                                Next
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default NewQuotes;
