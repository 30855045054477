import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoClose, IoTrash } from "react-icons/io5";
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom'; // Importa o hook useHistory
import MainSpinner from './Spinner'; // Ajuste o caminho conforme necessário
import "../components/css/PdfGatepassModal.css";

const PdfGatePassModal = ({ isOpen, onClose, loadTransportId }) => {
    const [gatePasses, setGatePasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory(); // Inicializa o hook useHistory

    useEffect(() => {
        if (isOpen && loadTransportId) {
            console.log('Modal is open and loadTransportId is provided:', loadTransportId);
            fetchGatePasses();
        }
    }, [isOpen, loadTransportId]);

    const fetchGatePasses = async () => {
        if (!loadTransportId) {
            console.error('loadTransportId is undefined');
            return;
        }

        setIsLoading(true);

        const userToken = localStorage.getItem('userToken');

        try {
            console.log('Fetching load transport data with ID:', loadTransportId);
            const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/loadtransport/${loadTransportId}`, {
                headers: {
                    "Screen-Name": "/Load",
                    "Authorization": `Bearer ${userToken}`
                }
            });

            console.log('Load Transport Response:', response);

            if (response.data && response.data.data) {
                const loadItems = response.data.data.load_items;
                console.log('Load Items:', loadItems);

                const gatePassesArray = [];

                for (const loadItem of loadItems) {
                    if (loadItem.gate_passes && loadItem.gate_passes.length > 0) {
                        console.log(`Gatepasses for Load Item ${loadItem.id}:`, loadItem.gate_passes);
                        gatePassesArray.push(...loadItem.gate_passes);
                    }
                }

                console.log('Collected Gate Passes:', gatePassesArray);
                setGatePasses(gatePassesArray);
            } else {
                console.error('No data found in load transport response.');
            }
        } catch (error) {
            console.error('Error fetching gate passes:', error);
        } finally {
            // Ensure that the spinner is active for at least 3 seconds
            setTimeout(() => {
                setIsLoading(false);
            }, 3000);
        }
    };

    const handleDeleteGatePass = async (loadItemId, gatePassId) => {
        try {
            console.log(`Deleting gate pass with id: ${gatePassId} for load item id: ${loadItemId}`);
            const userToken = localStorage.getItem('userToken');
            await axios.delete(`${process.env.REACT_APP_API_PREFIX}/loaditem/gate_pass/${loadItemId}`, {
                headers: {
                    "Screen-Name": "/InspectionLoadItem",
                    "Authorization": `Bearer ${userToken}`
                },
                data: { gatePassId } // Pass the gatePassId in the request body
            });
            Swal.fire({
                title: 'Deleted!',
                text: 'The PDF has been deleted.',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then(() => {
                fetchGatePasses(); // Refresh the list of gate passes
            });
        } catch (error) {
            console.error('Error deleting PDF:', error);
            Swal.fire('Error!', 'Failed to delete the PDF.', 'error');
        }
    };


    const openPdf = (url) => {
        window.open(url, '_blank');
    };

    return (
        isOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="bg-white rounded-lg p-8 w-1/3 shadow-lg mobile-gatepass">
                    <div className="flex justify-between items-center mb-6 border-b pb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">All Documents PDF</h2>
                        <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
                            <IoClose size={24} />
                        </button>
                    </div>
                    {isLoading ? (
                        <div className="flex justify-center items-center">
                            <MainSpinner />
                        </div>
                    ) : (
                        <div className="space-y-4">
                            {gatePasses.length > 0 ? (
                                gatePasses.map((gatePass, index) => (
                                    <div key={gatePass.id} className="flex justify-between items-center bg-orange-500 text-white py-3 px-6 rounded-lg shadow-md transition-transform transform hover:scale-105">
                                        <div
                                            className="flex-grow flex items-center cursor-pointer"
                                            onClick={() => openPdf(`${process.env.REACT_APP_API_FILE_PREFIX}/${gatePass.path}`)}
                                        >
                                            PDF {index + 1}
                                        </div>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteGatePass(gatePass.id);
                                            }}
                                            className="text-sm bg-red-600 p-2 rounded-full hover:bg-red-700 flex items-center transition-colors duration-200"
                                        >
                                            <IoTrash size={16} />
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-500">No documents PDF available.</p>
                            )}
                        </div>
                    )}
                    <button
                        onClick={onClose}
                        className="mt-6 flex items-center justify-center bg-gray-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-gray-700 transition-transform transform hover:scale-105 w-full"
                    >
                        Close
                    </button>
                </div>
            </div>
        )
    );
};

export default PdfGatePassModal;