import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { AiOutlineClear } from "react-icons/ai";

const SignatureSection = forwardRef((props, ref) => {
  const sigCanvas = useRef(null);

  // Expondo os métodos clear() e save() através da referência
  useImperativeHandle(ref, () => ({
    clear() {
      if (sigCanvas.current) {
        sigCanvas.current.clear();
      }
    },
    save() {
      if (sigCanvas.current) {
        return sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      }
      return null;
    },
  }), []); // Passando o array vazio garante que a função só será recriada se o componente for re-renderizado
  
  

  return (
    <div className="flex flex-col m-2 w-full mt-40 text-center -ml-21important">
      <div className="mb-2 block">
        <label htmlFor="customerSignature" className="block text-sm font-medium leading-6 text-gray-900 text-center">
          Customer Signature
        </label>
      </div>
      <div>
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{
            className: 'signatureCanvas bg-gray-50 border border-gray-300 text-gray-900 rounded-lg',
            style: { width: '70%', height: 200 }
          }}
        />
      </div>
      <button onClick={() => sigCanvas.current.clear()} className="mt-2 px-4 py-2 bg-orange-500 text-white rounded-md w-12">
        <AiOutlineClear />
      </button>
    </div>
  );
});

export default SignatureSection;
