import axios from "axios";
import React from "react";
import Card from "@material-tailwind/react/Card";
import { Button } from "@material-tailwind/react";

function PartnerCompanyPage() {
  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // get form data
    const formData = new FormData(e.target);

    // validate all inputs
    for (let [key, value] of formData.entries()) {
      if (value === "") {
        alert(`${key} is empty`);
        return;
      }
    }

    // get data from form and destructuring
    const {
      companyName,
      email,
      phone,
      dot,
      paymentServiceType,
      address,
      city,
      state,
      zipcode,
    } = Object.fromEntries(formData.entries());

    // create new partner company object
    const payload = {
      partner_company_name: companyName,
      phone: phone,
      email: email,
      dot: dot,
      payment_service_type: paymentServiceType,
      address: address,
      city: city,
      state: state,
      zipcode: zipcode,
      logo_path: "test",
    };

    // axios post
    // const response = await axios.post('http://localhost:8000/api/partnercompany', payload)
    // console.log(response)

    // try catch
    try {
      const response = await axios.post(
        "https://new.cajuu.app/public/api/partnercompany",
        payload
      );
      console.log(response);
      //toast.success("Partner Company Added Successfully");
    } catch (error) {
      console.log(error);
      //toast.error("Error Adding Partner Company");
    }
    // reset form
    e.target.reset();
  };

  return (
    <div className="px-4">
      <Card className="p-2 mt-4">
        <form className="flex flex-col"
          id="partnerCompanyForm"
          onSubmit={handleSubmit}>
          <div className="flex w-full flex-col p-10 border-1">

            <div className="mt-5">

              <div className="flex flex-wrap -mx-2">
                {/* CAMPO COMPANY NAME */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="companyname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company Name:</label>
                  <div className="flex">

                    <input
                      style={{ width: '18rem' }}
                      type="text"
                      name="companyName"
                      placeholder="Enter Company Name"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>

                {/* CAMPO E-MAIL */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-mail:</label>
                  <div className="flex">

                    <input
                      style={{ width: '18rem' }}
                      type="text"
                      id="email"
                      name="email"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="name@cajuu.com"
                    />
                  </div>
                </div>

                {/* CAMPO PHONE */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-mail:</label>
                  <div className="flex">

                    <input
                      style={{ width: '18rem' }}
                      type="tel"
                      id="phone"
                      name="phone"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="123-456-7890"
                    />
                  </div>
                </div>

                {/* CAMPO DOT */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="dot" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Dot:</label>
                  <div className="flex">
                    <input
                      style={{ width: '18rem' }}
                      type="number"
                      id="dot"
                      name="dot"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter dot"
                    />
                  </div>
                </div>

                {/* CAMPO PAGAMENTO SERVIÇO */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="paymentServiceType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Payment Service Type:
                  </label>
                  <div className="flex">
                    <select
                      name="paymentServiceType"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value="">Select Payment Service Type</option>
                      <option value="1">Quick Pay</option>
                      <option value="2">Factoring</option>
                      <option value="3">Fuel Card</option>
                    </select>
                  </div>
                </div>

                {/* CAMPO ENDEREÇO */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Address:</label>
                  <div className="flex">
                    <input
                      style={{ width: '18rem' }}
                      type="text"
                      id="address"
                      name="address"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter address"
                    />
                  </div>
                </div>

                {/* CAMPO CIDADE */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="City" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    City:
                  </label>
                  <div className="flex">
                    <input
                      style={{ width: '18rem' }}
                      type="text"
                      id="City"
                      name="City"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter City"
                    />
                  </div>
                </div>

                {/* CAMPO ESTADO */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="City" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    State:
                  </label>
                  <div className="flex">
                    <input
                      style={{ width: '18rem' }}
                      type="text"
                      id="state"
                      name="state"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter State"
                    />
                  </div>
                </div>

                {/* CAMPO ZIPCODE */}
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Zipcode:
                  </label>
                  <div className="flex">
                    <input
                      style={{ width: '18rem' }}
                      type="text"
                      id="zipcode"
                      name="zipcode"
                      className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter Zipcode"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-5">
              <Button type="submit" className="mx-2 mr-2" style={{ backgroundColor: 'orange', marginTop: '0rem' }}>
                {/* Submit Partner Company */}
                Alter Partner Company
              </Button>
              {/* <Button type="reset" className="mx-2 mr-2" style={{ backgroundColor: 'red', marginTop: '0rem' }}>
                 Reset Partner Company 
              </Button> */}
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default PartnerCompanyPage;
