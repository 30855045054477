import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importing icons from react-icons

const StatusModal = ({ isOpen, onClose, onSave, currentStatus, transport }) => {
    const [selectedStatusId, setSelectedStatusId] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(currentStatus);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        setSelectedStatus(currentStatus);
    }, [currentStatus]);

    const statusOptions = [
        { id: 1, name: "New" },
        { id: 2, name: "Assigned" },
        { id: 3, name: "Confirmed" },
        { id: 4, name: "Picked Up" },
        { id: 5, name: "Dropped Off" },
        { id: 6, name: "Delivered" },
        { id: 7, name: "Billed" },
        { id: 8, name: "Paid" },
        { id: 9, name: "Cancelled" },
        { id: 10, name: "Archived" },
    ];

    const handleSave = async () => {
        const userToken = localStorage.getItem('userToken');
        if (!userToken) {
            console.error("Token de usuário não encontrado.");
            Swal.fire('Error', 'User authentication failed.', 'error');
            return;
        }

        const idTransport = transport.id;
        const idStatus = selectedStatusId;

        try {
            const transportResponse = await axios.post(
                `${process.env.REACT_APP_API_PREFIX}/loadtransport/lifecycle_alternative/${idTransport}`,
                { "load_status_id": idStatus },
                {
                    headers: {
                        "Screen-Name": "/NewLoad",
                        "Authorization": `Bearer ${userToken}`
                    }
                }
            );

            if (transportResponse.data && transportResponse.data.data) {
                const nextStatusName = transportResponse.data.data.load_status.load_status_name;
                onSave(nextStatusName);
                console.log("Status atualizado com sucesso.");
                onClose();
            } else {
                console.error("Resposta da API inesperada, sem os dados necessários.");
                Swal.fire('Error', 'Unexpected API response.', 'error');
            }
        } catch (error) {
            console.error("Erro ao salvar o status:", error);
            Swal.fire('Error', 'Failed to update the status.', 'error');
        }
    };

    const findStatusName = (id) => {
        const status = statusOptions.find(s => s.id.toString() === id);
        return status ? status.name : '';
    };

    if (!isOpen) return null;

    const isCurrentStatusBeforePickedUp = ["New", "Assigned", "Confirmed"].includes(currentStatus);
    const isCurrentStatusDroppedOffOrLater = ["Dropped Off", "Delivered", "Billed", "Paid", "Cancelled", "Archived"].includes(currentStatus);

    const filteredStatusOptions = statusOptions.filter(option => {
        if (isCurrentStatusBeforePickedUp) {
            return ["Picked Up", "Cancelled"].includes(option.name);
        }
        return true;
    });

    return (
        <div className="fixed inset-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75 transition-opacity duration-300 ease-in-out">
            <div className="bg-white rounded-lg shadow-xl overflow-hidden w-11/12 md:w-1/2 lg:w-1/3 animate-fadeIn">
                <div className="flex justify-between items-center px-4 py-3 border-b border-gray-200 bg-gray-50">
                    <h5 className="text-lg font-semibold text-gray-900">Change the Load Status</h5>
                    <button onClick={onClose} className="text-gray-400 hover:text-gray-600 focus:outline-none">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="p-4">
                    <div className="mb-4 p-3 bg-red-100 text-red-700 text-sm rounded-md">
                        Be aware that all files generated up to the current status may be deleted. Including BOL, Invoice, inspection diagrams and vehicle photos.
                    </div>
                    <div className="mb-4">
                        <label htmlFor="select_load_status" className="block text-sm font-medium text-gray-700 mb-2">
                            Select the new status below:
                        </label>
                        <div className="relative">
                            <select
                                id="select_load_status"
                                value={selectedStatusId}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setSelectedStatusId(e.target.value);
                                }}
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                className="form-select block w-full border border-gray-300 text-gray-700 bg-white shadow-sm focus:shadow-outline hover:border-gray-400 text-sm rounded-md appearance-none pr-10"
                                aria-label="Select Load Status"
                            >
                                <option value="" disabled>Select a load status</option>
                                {filteredStatusOptions.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                {isDropdownOpen ? <FaChevronUp className="h-5 w-5" /> : <FaChevronDown className="h-5 w-5" />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end px-4 py-3 bg-gray-50 text-right border-t border-gray-200 space-x-3">
                    <button className="py-2 px-4 text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition duration-150 ease-in-out" onClick={onClose}>Cancel</button>
                    {!isCurrentStatusDroppedOffOrLater && (
                        <button className="py-2 px-4 text-sm font-medium rounded-md text-white bg-orange-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out" onClick={handleSave}>Save</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StatusModal;
