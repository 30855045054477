import { useEffect, useState } from 'react';
import MainSpinner from '../components/MainSpinner';
import SettingsForm from 'components/SettingsForm';
import ProfileCard from 'components/ProfileCard';

export default function Dashboard() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); // 4 segundos

        return () => clearTimeout(timer); // Limpa o timer se o componente desmontar
    }, []);

    return (
        <>
            {loading ? (
                <MainSpinner />
            ) : (
                <div className="px-3 md:px-8 h-auto -mt-24">
                    <div className="container mx-auto max-w-full mt-32">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-5 px-4 mb-16">
                                <SettingsForm />
                            </div>
                            <div className="xl:col-start-5 xl:col-end-7 px-4 mb-16 mt-14">
                                <ProfileCard />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
